import React from 'react';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { ToastWrapper } from '@ibox/ui';

import { store, history } from './store';
import { APIConnector } from './api';
import { Layout } from './components/layout';
import { Auth } from './pages/auth';
import { interceptor } from './api/inceptor';
import { Theme } from './components/themes';
import {
  ApplicationConfigProvider,
  getApplicationConfig,
} from './components/application-config/application-config';

interceptor(store);

export const App = () => (
  <Provider store={store}>
    <ApplicationConfigProvider value={getApplicationConfig()}>
      <APIConnector>
        <Theme>
          <Auth>
            <ConnectedRouter history={history}>
              <Layout />
            </ConnectedRouter>
          </Auth>
          <ToastWrapper />
        </Theme>
      </APIConnector>
    </ApplicationConfigProvider>
  </Provider>
);
