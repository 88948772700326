import fp from 'lodash/fp';

// eslint-disable-next-line import/no-named-as-default
import { routes } from '../routes';
import faviconAM from '../assets/favicons/faviconAM.ico';
import faviconUZ from '../assets/favicons/faviconUZ.ico';
import favicon from '../assets/favicons/favicon.ico';
import { CountryCode } from '../store/reducers';

const routesTitles = [
  {
    link: routes.participants(),
    title: 'Участники товарооборота',
  },
  {
    link: routes.prefixes(),
    title: 'Префиксы GS1 (GCP)',
  },
  {
    link: routes.products(),
    title: 'Товары',
  },
  {
    link: routes.productGroups(),
    title: 'Товарные группы',
  },
];

export const HELPERS = {
  getPageTitle: (url: string) =>
    url === '/'
      ? 'Домашняя страница'
      : fp.pipe(
          fp.find((item) => url.includes(item.link)),
          fp.get('title')
        )(routesTitles),
  parseErrors: (errors) => {
    if (errors && errors.errors && errors.errors.length !== undefined) {
      return fp
        .map((v) => v.defaultMessage)(errors.errors)
        .join(', ');
    }
    return errors && (errors.message || errors.error_description);
  },
  isPhone: (data: string) =>
    /^((8|\+7)[-]?)?(\(?\d{3}\)?[-]?)?[\d-]{10,10}$/.test(data),
};

const getFaviconByCountry = (countryCode: CountryCode) => {
  switch (countryCode) {
    case 'KZ':
      return favicon;
    case 'AZ':
      return favicon;
    case 'HY':
      return faviconAM;
    case 'AM':
      return faviconAM;
    case 'UZ':
      return faviconUZ;
    default:
      return favicon;
  }
};

export const setFavicon = (countryCode: CountryCode) => {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = getFaviconByCountry(countryCode);
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const setPageTitle = (countryCode: CountryCode) => {
  document.title = countryCode === 'UZ' ? 'Asl Belgisi' : 'АИС МТ';
};

export function generateGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export const isIncorrectInputError = (err) =>
  err?.response?.data?.description === 'Incorrect input data';
