import { Types, types } from '../types';

import { Name } from '.';

type Enum<T = string> = {
  code: T;
  translations: Name;
}[];

export type EnumNamedItem<T = string> = {
  code: T;
  name: Name;
};

type EnumGtins = {
  gtin: string;
  product_name: Name;
}[];

type EnumTnved = (EnumNamedItem & {
  acceptable: boolean;
  validity_date: string;
  product_group: string;
})[];

type EnumsState = Record<string, Enum> & {
  gtins: EnumGtins;
  tnved: EnumTnved;
  nationalClassifier: { code: string; name: Name }[];
};

const initialState: EnumsState = {
  gtins: [],
  tnved: [],
  nationalClassifier: [],
};

type EnumsActionType = Types[keyof Pick<
  Types,
  'TNVED_SET_LIST' | 'NATIONAL_SET_LIST' | 'ENUM_SET_LIST'
>];

type EnumsAction = {
  type: EnumsActionType;
  payload: unknown;
};

export const reducer = (state = initialState, action: EnumsAction) => {
  switch (action.type) {
    case types.TNVED_SET_LIST:
      return {
        ...state,
        tnved: action.payload,
      };
    case types.NATIONAL_SET_LIST:
      return {
        ...state,
        nationalClassifier: action.payload,
      };
    case types.ENUM_SET_LIST:
      return {
        ...state,
        [action.payload.name]: [...action.payload.values],
      };
    default:
      return state;
  }
};
