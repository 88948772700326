import React, { ReactNode, useEffect, useState } from 'react';

import fp from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@ibox/ui';

import { check } from '../../store/actions/auth';
import { RootState } from '../../store';

export const Auth = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dsp = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dsp(
      check({
        onFinish: (isNeedLogin: boolean) => {
          if (!isNeedLogin) {
          }
          setIsLoading(false);
        },
      })
    );
  }, []);
  // return <>{children}</>;
  return (
    // eslint-disable-next-line no-nested-ternary
    fp.isEmpty(auth) || isLoading ? (
      <div className="spinnerWrap">
        <Spinner />
      </div>
    ) : auth.isNotAuth ? (
      <div />
    ) : (
      <>{children}</>
    )
  );
};
