// eslint-disable-next-line no-restricted-imports
import { get, isEmpty } from 'lodash';

import { showError, showSuccess } from '@ibox/ui';

import { generateGuid } from '../../helpers';
import { Translate } from '../translate';

const parseErrors = (payload) => {
  const err = get(payload, 'response.data');
  const commonErrors = [];
  if (err) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { shortages, description, violations, unknown_attributes } = err;

    if (shortages && !isEmpty(shortages)) {
      const message = [description];
      const langs = Object.keys(shortages);
      langs.forEach((lang) => {
        const str = `${lang}: ${shortages[lang].join(', ')}`;
        message.push(str);
      });
      return [{ id: generateGuid(), name: message }];
    }

    if (unknown_attributes && unknown_attributes.length) {
      const message = [description, 'unknown attributes:'];
      const attr = unknown_attributes.join(', ');
      message.push(attr);
      return [{ id: generateGuid(), name: message }];
    }

    if (violations && violations.length) {
      const errors = violations.map((el) => el.message);
      const message = [description, ...errors];
      return [{ id: generateGuid(), name: message }];
    }
    if (Array.isArray(err)) {
      return [{ id: generateGuid(), name: err.message }];
    }
    commonErrors.push({
      id: generateGuid(),
      name: description || Translate('Произошла ошибка'),
    });
  } else {
    commonErrors.push({
      id: generateGuid(),
      name: Translate('Произошла ошибка'),
    });
  }
  return [...commonErrors];
};

export const showErrorToast = (props) => showError(parseErrors, props);
export const showSuccessToast = (props) => showSuccess(props);
