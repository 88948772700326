import React from 'react';

import { Route, RouteProps } from 'react-router';
import { useTranslation } from 'react-i18next';

import { NotFoundPage, ServerErrorPage } from '@ibox/ui';

import { routes } from '../../routes';

type AuthRouteProps = RouteProps & {
  serverError: boolean;
};

export const ErrorRoute = React.memo<AuthRouteProps>(
  ({ serverError, ...props }) => {
    const { t } = useTranslation();

    return serverError ? (
      <Route
        component={() => (
          <ServerErrorPage
            accessDeniedComment={t(
              'Недостаточно прав для выполнения операции. Обратитесь к своему администратору.'
            )}
            accessDeniedMessage={t('Доступ запрещен')}
            linkText={t('Перейти на главную')}
            notFoundMessage={t('Запрашиваемый объект не найден')}
            redirectLink={routes.products()}
            reloadText={t('Обновить страницу')}
            serverErrorComment={t(
              'Системная ошибка. Попробуйте обновить страницу.Если проблема сохраняется, обратитесь в службу поддержки.'
            )}
            serverErrorMessage={t('Технические неполадки')}
          />
        )}
        {...props}
      />
    ) : (
      <Route
        component={() => (
          <NotFoundPage
            contentText={t(
              'К сожалению, запрошенной вами страницы не существует.'
            )}
            headerText={t('Страница не найдена')}
            linkText={t('Перейти на главную')}
            redirectLink={routes.products()}
          />
        )}
        {...props}
      />
    );
  }
);
