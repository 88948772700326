import React from 'react';

import { Redirect, Route, RouteProps } from 'react-router';

import { routes } from '../../routes';

type AuthRouteProps = RouteProps & {
  hasAccess: boolean;
};

export const AuthRoute = React.memo<AuthRouteProps>(({ hasAccess, ...props }) =>
  hasAccess ? (
    <Route {...props} />
  ) : (
    <Redirect to={routes.serverError('', { code: 403 })} />
  )
);
