import { Reducer } from '@reduxjs/toolkit';

import { type Types, types } from '../types';

type CurrentUserState = {
  id: number;
  inn: string;
  full_name: string;
};

type ActionType = Types[keyof Pick<Types, 'SET_USER_DATA'>];

type Action = {
  type: ActionType;
  payload: unknown;
};

const initialState = {} as CurrentUserState;

export const reducer: Reducer<CurrentUserState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.SET_USER_DATA:
      return action.payload as CurrentUserState;
    default:
      return state;
  }
};
