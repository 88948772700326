import { Reducer } from '@reduxjs/toolkit';

import { type Types, types } from '../types';
import { getCookie } from '../../utils/cookieHelper';

import { CountryCode, Language } from '.';

type SettingsState = {
  common: {
    countryCode: CountryCode;
  };
  isLoading: boolean;
  language: Language;
  defaultLanguage: Language;
  rejectedLanguages: Language[];
};

export const FALLBACK_LANGUAGE: Language = 'ru';

const availableLanguages: Record<string, Language> = {
  ru: 'ru',
  uz: 'uz',
  hy: 'hy',
  kz: 'kz',
} as const;

export const validateLanguage = (language: string): language is Language =>
  !!availableLanguages[language];

const languageInCookie = getCookie('language') ?? '';

const initialState: SettingsState = {
  common: {
    countryCode: (window.env.COUNTRY_CODE || 'RU') as CountryCode,
  },
  isLoading: true,
  language: validateLanguage(languageInCookie)
    ? languageInCookie
    : validateLanguage(window.env.DEFAULT_LANGUAGE)
    ? window.env.DEFAULT_LANGUAGE
    : FALLBACK_LANGUAGE,
  defaultLanguage: validateLanguage(window.env.DEFAULT_LANGUAGE)
    ? window.env.DEFAULT_LANGUAGE
    : FALLBACK_LANGUAGE,
  rejectedLanguages: [],
};

type SettingsActionType = Types[keyof Pick<
  Types,
  | 'SET_COMMON_SETTINGS'
  | 'SET_IS_LOADING'
  | 'SET_LANGUAGE'
  | 'SET_REJECTED_LANGS'
>];

type SettingsAction = {
  type: SettingsActionType;
  payload: unknown;
};

export const reducer: Reducer<SettingsState, SettingsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.SET_COMMON_SETTINGS:
      return {
        ...state,
        common: action.payload as SettingsState['common'],
      };
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload as SettingsState['isLoading'],
      };
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload as SettingsState['language'],
      };
    case types.SET_REJECTED_LANGS:
      return {
        ...state,
        rejectedLanguages: action.payload as SettingsState['rejectedLanguages'],
      };
    default:
      return state;
  }
};
