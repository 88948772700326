import { ActionType } from '../store/types';

type Action<T> = {
  type: T;
  payload: unknown;
};

export const createAction =
  <T extends ActionType>(type: T) =>
  (payload?: unknown): Action<T> => ({
    type,
    payload: payload,
  });
