import { connect } from 'react-redux';

import RestAPI from './rest';

export const API: any = {};

export const APIConnector = connect(
  (state: any) => ({ auth: state.auth }),
  {}
)(({ children, ...props }) => {
  API.rest = RestAPI(props);
  return children;
});

/**
 * @deprecated
 * use named import instead
 * `import {API} from '...'`
 * */
const defaultExport = API;

// eslint-disable-next-line import/no-default-export
export default defaultExport;
