import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';

import translationRU from './locales/ru/translations.json';
import translationUZ from './locales/uz/translations.json';
import translationEN from './locales/en/translations.json';
import translationHY from './locales/hy/translations.json';
import { getCookie, setCookie } from './utils/cookieHelper';

const language =
  getCookie('language')?.toLowerCase() === 'en'
    ? 'ru'
    : getCookie('language')?.toLowerCase() || 'ru';

setCookie('language', language);

const resources = {
  ru: {
    translation: translationRU,
  },
  uz: {
    translation: translationUZ,
  },
  hy: {
    translation: translationHY,
  },
  en: {
    translation: translationEN,
  },
  hy: {
    translation: translationHY,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    backend: {
      loadPath: '/locales/{{lng}}/translations.json',
    },
    fallbackLng: 'ru',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
