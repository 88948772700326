import React from 'react';

import { intersection, mapValues } from 'lodash/fp';

import { getTokenPayload } from '../../utils/utils';

type BackendEntity =
  | 'RMT-ORG'
  | 'RMT-ORG-PREFIX'
  | 'RMT-PRODUCT-CHECK'
  | 'RMT-CHECKED-PRODUCT'
  | 'RMT-PG'
  | 'RMT-PRODUCT'
  | 'RMT-PRODUCT-DRAFT';

type BackendEntityAction =
  | 'ADMINISTRATION'
  | 'CREATE'
  | 'DELETE'
  | 'READ'
  | 'WRITE';

type Permission = `${BackendEntity}.${BackendEntityAction}`;

type Role =
  | 'ROLE_ORG_TRADE_PARTICIPANT'
  | 'ROLE_ORG_PRODUCER'
  | 'ROLE_ORG_IMPORTER'
  | 'ROLE_ORG_OGV'
  | 'ROLE_HEAD_OGV'
  | 'ROLE_HEAD_OPERATOR'
  | 'ROLE_ORG_IS_MP_OPERATOR';

type Authority = Role | Permission;

type Page =
  | 'participants'
  | 'prefixes'
  | 'products'
  | 'productGroups'
  | 'tasks';

export type Entity = Page | 'productDrafts' | 'productChecked';

type EntityAction =
  | 'canRead'
  | 'canWrite'
  | 'canCreate'
  | 'canDelete'
  | 'isAdministration';

type ProductGroup = {
  name: string;
  status: string;
  types: string[];
};

const mapEntities: Record<Entity, BackendEntity> = {
  participants: 'RMT-ORG',
  prefixes: 'RMT-ORG-PREFIX',
  products: 'RMT-PRODUCT',
  productDrafts: 'RMT-PRODUCT-DRAFT',
  productChecked: 'RMT-CHECKED-PRODUCT',
  productGroups: 'RMT-PG',
  tasks: 'RMT-PRODUCT-CHECK',
} as const;

const mapActions: Record<EntityAction, BackendEntityAction> = {
  canRead: 'READ',
  canWrite: 'WRITE',
  canCreate: 'CREATE',
  canDelete: 'DELETE',
  isAdministration: 'ADMINISTRATION',
} as const;

const getTokenAuthorities = (): Authority[] =>
  getTokenPayload()?.authorities ?? [];

const getCheckAuthorities =
  (userAuthorities: Authority[]) =>
  (...allowedAuthorities: Authority[]) =>
    intersection(userAuthorities)(allowedAuthorities).length > 0;

const authorities = getTokenAuthorities();
const checkAuthorities = getCheckAuthorities(authorities);
const isUserOPR = checkAuthorities('ROLE_ORG_IS_MP_OPERATOR');
export const isUserUOT =
  checkAuthorities(
    'ROLE_ORG_TRADE_PARTICIPANT',
    'ROLE_ORG_PRODUCER',
    'ROLE_ORG_IMPORTER'
  ) &&
  !checkAuthorities(
    'ROLE_ORG_OGV',
    'ROLE_HEAD_OGV',
    'ROLE_HEAD_OPERATOR',
    'ROLE_ORG_IS_MP_OPERATOR'
  );

const getTransitionsRights = () => {
  const productGroups = getTokenPayload()?.product_group_info || [];
  const suzProductGroups = (window.env.SUZ_PRODUCT_GROUPS || '').split(',');
  const availableGroups = productGroups
    .filter((pg: ProductGroup) =>
      pg.name === 'appliances'
        ? pg
        : pg.types.includes('PRODUCER') || pg.types.includes('IMPORTER')
    )
    .filter((pg: ProductGroup) => pg.status === '5' || pg.status === '4')
    .map((pg: ProductGroup) => pg.name);
  const suzProductGroupNotInclude = availableGroups.filter(
    (item: string) => suzProductGroups.indexOf(item) === -1
  );
  return {
    icom: isUserOPR ? false : suzProductGroupNotInclude.length > 0,
  };
};

type EntityConfig = Record<string, boolean> & {
  canRead: boolean;
  canWrite: boolean;
  canCreate: boolean;
  canDelete: boolean;
  isAdministration: boolean;
};

export type ApplicationConfig = Record<Entity, EntityConfig>;

type TransitionsConfig = {
  transitions: {
    icom: boolean;
  };
};

export const getApplicationConfig = (): ApplicationConfig &
  TransitionsConfig => {
  const transitions = getTransitionsRights();

  const commonConfig = mapValues(
    (backendEntity: BackendEntity) =>
      mapValues((backendEntityAction: BackendEntityAction) =>
        checkAuthorities(`${backendEntity}.${backendEntityAction}`)
      )(mapActions) as EntityConfig
  )(mapEntities) as ApplicationConfig;

  const canWritePublishedByUserOPR =
    isUserOPR &&
    commonConfig.products.canWrite &&
    window.env.RMT_OPERATOR_PRODUCT_API_ENABLED;

  return {
    ...commonConfig,
    productDrafts: {
      ...commonConfig.productDrafts,
      canWrite:
        commonConfig.productDrafts.canWrite && !canWritePublishedByUserOPR,
    },
    products: {
      ...commonConfig.products,
      canWrite: isUserUOT && commonConfig.products.canWrite,
      canWritePublishedByUserOPR,
      canWriteNC: isUserOPR && window.env.RMT_OPERATOR_PRODUCT_API_ENABLED,
    },
    prefixes: {
      ...commonConfig.prefixes,
      hasActions: !window.env.RMT_GS1_ENABLED,
      isUserOPR,
      isUserUOT,
    },
    transitions,
  };
};

const ApplicationConfigContext = React.createContext<
  (ApplicationConfig & TransitionsConfig) | undefined
>(undefined);

export const ApplicationConfigProvider = ApplicationConfigContext.Provider;

export function useApplicationConfig() {
  const applicationConfig = React.useContext(ApplicationConfigContext);

  if (!applicationConfig) {
    throw new Error('ApplicationConfig is not defined!');
  }

  return applicationConfig;
}
