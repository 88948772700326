import axios from 'axios';
import { push } from 'react-router-redux';

import { setAuthData } from '../store/actions/auth';
import { storeTokens } from '../store/sagas/auth';
import { getParamFromToken } from '../utils/utils';
import { routes } from '../routes';

import { API } from './index';

let isRefreshing = false;
let subscribers = [];

// eslint-disable-next-line @typescript-eslint/naming-convention
function onRefreshed({ access_token }) {
  subscribers.map((cb) => cb(access_token));
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

export const interceptor = (store) => {
  axios.interceptors.response.use(null, async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401) {
      if (
        originalRequest.url === '/oauth/oauth2' &&
        originalRequest.method !== 'delete'
      ) {
        return Promise.reject(error.response);
      }
      if (!isRefreshing) {
        isRefreshing = true;
        // eslint-disable-next-line promise/no-promise-in-callback
        API.rest.auth
          .reconnect()
          .then((token) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const exp_date = getParamFromToken('exp', token, 'access_token');
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const exp_date_refresh = getParamFromToken(
              'exp',
              token,
              'refresh_token'
            );
            storeTokens({ ...token, exp_date, exp_date_refresh });
            store.dispatch(
              setAuthData(
                token
                  ? { ...token, exp_date, exp_date_refresh }
                  : { isNotAuth: true }
              )
            );
            isRefreshing = false;
            if (token) {
              onRefreshed(token);
            }
            subscribers = [];
            return null;
          })
          .catch((err) => {
            console.error('connection recovery failed', err);
          });
      }
      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }
    if (
      error?.response?.status === 403 ||
      Math.trunc(error?.response?.status / 100) === 5
    ) {
      store.dispatch(
        push(
          routes.serverError('', {
            code: error?.response?.data?.code || 500,
          })
        )
      );
    }
    return Promise.reject(error);
  });
};
