import React, { lazy, Suspense, useMemo } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { Spinner } from '@ibox/ui';

import { routes } from '../../routes';
import { useApplicationConfig } from '../application-config/application-config';
import { AuthRoute } from '../auth-route/auth-route';
import { ErrorRoute } from '../error-route';
import { useEnums } from '../../api-generated/api-hooks';

import { HeaderWrapper } from './header-wrapper/header-wrapper';

import css from './index.module.scss';

const Participants = lazy(() =>
  import('../../pages/participants').then((module) => ({
    default: module.Participants,
  }))
);

const ParticipantView = lazy(() =>
  import('../../pages/participants/view/index').then((module) => ({
    default: module.ParticipantView,
  }))
);

const Prefixes = lazy(() =>
  import('../../pages/prefixes').then((module) => ({
    default: module.Prefixes,
  }))
);

const Products = lazy(() =>
  import('../../pages/products').then((module) => ({
    default: module.Products,
  }))
);

const ProductForm = lazy(() =>
  import('../../pages/products/form').then((module) => ({
    default: module.ProductForm,
  }))
);
const ProductView = lazy(() =>
  import('../../pages/products/view').then((module) => ({
    default: module.ProductView,
  }))
);
const Tasks = lazy(() =>
  import('../../pages/tasks').then((module) => ({
    default: module.Tasks,
  }))
);
const ProductGroups = lazy(() =>
  import('../../pages/productGroups').then((module) => ({
    default: module.ProductGroupsPage,
  }))
);
const ProductGroupView = lazy(() =>
  import('../../pages/productGroups/view').then((module) => ({
    default: module.ProductGroupView,
  }))
);

const SPINNER = <Spinner className={css.spinner} />;

export const Layout = () => {
  const accessRights = useApplicationConfig();

  const redirectRoutPath = useMemo(
    () =>
      accessRights.tasks.canRead || accessRights.tasks.isAdministration
        ? routes.tasks()
        : routes.products(),
    [accessRights.tasks.canRead, accessRights.tasks.isAdministration]
  );

  const { EnumsLoader } = useEnums();

  return (
    <Suspense fallback={SPINNER}>
      <EnumsLoader>
        <div className={css.layout}>
          <div className={css.header}>
            <HeaderWrapper />
          </div>
          <div className={css.main}>
            <div className={css.page}>
              <Suspense fallback={SPINNER}>
                <Switch>
                  <AuthRoute
                    exact
                    component={Tasks}
                    hasAccess={accessRights.tasks.canRead}
                    path={routes.tasks()}
                  />
                  <AuthRoute
                    exact
                    component={ProductView}
                    hasAccess={accessRights.tasks.canRead}
                    path={routes.tasks(':id')}
                  />
                  <AuthRoute
                    exact
                    component={Products}
                    hasAccess={accessRights.products.canRead}
                    path={routes.products()}
                  />
                  <AuthRoute
                    component={ProductForm}
                    hasAccess={accessRights.products.canWrite}
                    path={routes.products('create')}
                  />
                  <AuthRoute
                    exact
                    component={ProductView}
                    hasAccess={accessRights.products.canRead}
                    path={routes.products(':id')}
                  />
                  <AuthRoute
                    component={ProductForm}
                    hasAccess={
                      accessRights.products.canWrite ||
                      accessRights.products.canWriteNC
                    }
                    path={routes.products('edit/:id')}
                  />
                  <AuthRoute
                    component={ProductForm}
                    hasAccess={
                      accessRights.products.canWrite ||
                      accessRights.products.canWriteNC
                    }
                    path={routes.products('edit-published/:id')}
                  />
                  <AuthRoute
                    exact
                    component={Participants}
                    hasAccess={accessRights.participants.canRead}
                    path={routes.participants()}
                  />
                  <AuthRoute
                    exact
                    component={ParticipantView}
                    hasAccess={accessRights.participants.canRead}
                    path={routes.participants(':id')}
                  />
                  <AuthRoute
                    component={Prefixes}
                    hasAccess={accessRights.prefixes.canRead}
                    path={routes.prefixes()}
                  />
                  <AuthRoute
                    exact
                    component={ProductGroups}
                    hasAccess={accessRights.productGroups.canRead}
                    path={routes.productGroups()}
                  />
                  <AuthRoute
                    exact
                    component={ProductGroupView}
                    hasAccess={accessRights.productGroups.canRead}
                    path={routes.productGroups(':id')}
                  />
                  <ErrorRoute exact serverError path={routes.serverError()} />
                  <Route exact path="/">
                    <Redirect to={redirectRoutPath} />
                  </Route>
                  <ErrorRoute path="*" serverError={false} />
                </Switch>
              </Suspense>
            </div>
          </div>
        </div>
      </EnumsLoader>
    </Suspense>
  );
};
