import { reducer as settingsReducer } from './settings';
import { reducer as enumsReducer } from './enums';
import { reducer as productsReducer } from './products';
import { reducer as authReducer } from './auth';
import { reducer as currentUserReducer } from './currentUser';
import { reducer as tasksReducer } from './tasks';

export type Language = 'en' | 'ru' | 'uz' | 'hy' | 'kz';
export type CountryCode = 'AM' | 'AZ' | 'UZ' | 'HY' | 'KZ';

export type Name<T = string> = Record<Language, T>;

export type TranslatedString = {
  name: Name;
  value: string;
};

export type PaginationData = { limit: number; page: number; offset: number };

export type PageState<T extends object = {}, I = unknown> = {
  list: T[];
  item?: I;
  filter: unknown;
  sorting: unknown;
  isLastPage: boolean;
  pagination: PaginationData;
  total?: number;
};

export type PageQueryParams<
  T extends { filter: unknown; sorting: unknown; pagination: PaginationData }
> = Pick<T, 'filter' | 'pagination' | 'sorting'>;

export { type RootState } from '../';

export const reducers = {
  auth: authReducer,
  settings: settingsReducer,
  enums: enumsReducer,
  products: productsReducer,
  currentUser: currentUserReducer,
  tasks: tasksReducer,
};
