import { apiEmpty as api } from './api-empty';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveProductDraftTech: build.mutation<
      SaveProductDraftTechApiResponse,
      SaveProductDraftTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getTaskComments: build.query<
      GetTaskCommentsApiResponse,
      GetTaskCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/task/${queryArg.taskId}/comments`,
      }),
    }),
    saveTaskComments: build.mutation<
      SaveTaskCommentsApiResponse,
      SaveTaskCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/task/${queryArg.taskId}/comments`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    updateTaskStatus: build.mutation<
      UpdateTaskStatusApiResponse,
      UpdateTaskStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/task/${queryArg.id}/status/${queryArg.status}`,
        method: 'PUT',
      }),
    }),
    getProduct: build.query<GetProductApiResponse, GetProductApiArg>({
      query: (queryArg) => ({ url: `/api/v1/product/${queryArg.id}` }),
    }),
    saveProductDraft: build.mutation<
      SaveProductDraftApiResponse,
      SaveProductDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.productUpdateRequest,
      }),
    }),
    deleteProduct: build.mutation<
      DeleteProductApiResponse,
      DeleteProductApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getPrefixes: build.query<GetPrefixesApiResponse, GetPrefixesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/prefixes`,
        params: {
          orderBy: queryArg.orderBy,
          orderDirection: queryArg.orderDirection,
          status: queryArg.status,
          prefix: queryArg.prefix,
          inn: queryArg.inn,
          name: queryArg.name,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    addPrefix: build.mutation<AddPrefixApiResponse, AddPrefixApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/prefixes`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    editProductPublished: build.mutation<
      EditProductPublishedApiResponse,
      EditProductPublishedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/operator/product/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.productOperatorUpdateRequest,
      }),
    }),
    getOrganization: build.query<
      GetOrganizationApiResponse,
      GetOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations/${queryArg.inn}`,
      }),
    }),
    autoUpdateOrganization: build.mutation<
      AutoUpdateOrganizationApiResponse,
      AutoUpdateOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations/${queryArg.inn}`,
        method: 'PUT',
      }),
    }),
    createProductTech: build.mutation<
      CreateProductTechApiResponse,
      CreateProductTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product`,
        method: 'POST',
        body: queryArg.productCreateInternalRequest,
      }),
    }),
    uploadFilesTech: build.mutation<
      UploadFilesTechApiResponse,
      UploadFilesTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/${queryArg.id}/files`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    loadOrganizationsDataTech: build.mutation<
      LoadOrganizationsDataTechApiResponse,
      LoadOrganizationsDataTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/organization`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    addOrganizationPrefixesTech: build.mutation<
      AddOrganizationPrefixesTechApiResponse,
      AddOrganizationPrefixesTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/organization/${queryArg.inn}/prefixes`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    importGtinFromGs1Tech: build.mutation<
      ImportGtinFromGs1TechApiResponse,
      ImportGtinFromGs1TechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/import-from-gs1`,
        method: 'POST',
        body: queryArg.gs1ProductReqDto,
      }),
    }),
    getProducts: build.query<GetProductsApiResponse, GetProductsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/product`,
        params: {
          offset: queryArg.offset,
          orderBy: queryArg.orderBy,
          orderDirection: queryArg.orderDirection,
          status: queryArg.status,
          gtin: queryArg.gtin,
          techGtin: queryArg.techGtin,
          tnved: queryArg.tnved,
          productGroup: queryArg.productGroup,
          productCategory: queryArg.productCategory,
          packageType: queryArg.packageType,
          inn: queryArg.inn,
          productName: queryArg.productName,
          languageComplete: queryArg.languageComplete,
          sortLanguage: queryArg.sortLanguage,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          limit: queryArg.limit,
        },
      }),
    }),
    createProduct: build.mutation<
      CreateProductApiResponse,
      CreateProductApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product`,
        method: 'POST',
        body: queryArg.productCreateRequest,
      }),
    }),
    transferProduct: build.mutation<
      TransferProductApiResponse,
      TransferProductApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product/transfer`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    uploadFiles: build.mutation<UploadFilesApiResponse, UploadFilesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/product/files`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getAllPrefixes: build.mutation<
      GetAllPrefixesApiResponse,
      GetAllPrefixesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/prefixes/all`,
        method: 'POST',
        body: queryArg.prefixFilterDto,
        params: {
          orderBy: queryArg.orderBy,
          orderDirection: queryArg.orderDirection,
          status: queryArg.status,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    sendProductToIntegration: build.mutation<
      SendProductToIntegrationApiResponse,
      SendProductToIntegrationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/operator/product/integration/send`,
        method: 'POST',
        body: queryArg.sendToIntegrationReqDto,
      }),
    }),
    getOrganizations: build.query<
      GetOrganizationsApiResponse,
      GetOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations`,
        params: {
          sortLanguage: queryArg.sortLanguage,
          orderBy: queryArg.orderBy,
          orderDirection: queryArg.orderDirection,
          status: queryArg.status,
          offset: queryArg.offset,
          limit: queryArg.limit,
          inn: queryArg.inn,
          name: queryArg.name,
          gs1_resident: queryArg.gs1Resident,
        },
      }),
    }),
    filteredOrganizations: build.mutation<
      FilteredOrganizationsApiResponse,
      FilteredOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations`,
        method: 'POST',
        body: queryArg.organizationFilterDto,
        params: {
          sortLanguage: queryArg.sortLanguage,
          orderBy: queryArg.orderBy,
          orderDirection: queryArg.orderDirection,
          status: queryArg.status,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    importOrganizationFromIsmpt: build.mutation<
      ImportOrganizationFromIsmptApiResponse,
      ImportOrganizationFromIsmptApiArg
    >({
      query: () => ({
        url: `/api/v1/admin/organizations/import-from-ismpt`,
        method: 'POST',
      }),
    }),
    importOrganizationFromGs1: build.mutation<
      ImportOrganizationFromGs1ApiResponse,
      ImportOrganizationFromGs1ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations/import-from-gs1`,
        method: 'POST',
        body: queryArg.gs1OrganizationReqDto,
      }),
    }),
    publishProductTech: build.mutation<
      PublishProductTechApiResponse,
      PublishProductTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/${queryArg.id}/status/publish`,
        method: 'PATCH',
      }),
    }),
    sendProductToModerationTech: build.mutation<
      SendProductToModerationTechApiResponse,
      SendProductToModerationTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/${queryArg.id}/status/moderate`,
        method: 'PATCH',
      }),
    }),
    assigneeTask: build.mutation<AssigneeTaskApiResponse, AssigneeTaskApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/task/assignee/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.taskAssigneeRequest,
      }),
    }),
    sendProductToModeration: build.mutation<
      SendProductToModerationApiResponse,
      SendProductToModerationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product/${queryArg.id}/status/moderate`,
        method: 'PATCH',
        body: queryArg.moderationReqDto,
      }),
    }),
    blockProduct: build.mutation<BlockProductApiResponse, BlockProductApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/product/${queryArg.id}/status/block`,
        method: 'PATCH',
      }),
    }),
    blockPrefix: build.mutation<BlockPrefixApiResponse, BlockPrefixApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/prefixes/${queryArg.prefix}/block`,
        method: 'PATCH',
        body: queryArg.prefixDescriptionDto,
        params: { inn: queryArg.inn, targetInn: queryArg.targetInn },
      }),
    }),
    updateOrganizationNames: build.mutation<
      UpdateOrganizationNamesApiResponse,
      UpdateOrganizationNamesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations/${queryArg.inn}/name`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    blockOrganizationByInn: build.mutation<
      BlockOrganizationByInnApiResponse,
      BlockOrganizationByInnApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations/${queryArg.inn}/block`,
        method: 'PATCH',
      }),
    }),
    activateOrganization: build.mutation<
      ActivateOrganizationApiResponse,
      ActivateOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations/${queryArg.inn}/activate`,
        method: 'PATCH',
      }),
    }),
    editProductPublishedTech: build.query<
      EditProductPublishedTechApiResponse,
      EditProductPublishedTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/${queryArg.id}/edit`,
      }),
    }),
    getProductTemplateTech: build.query<
      GetProductTemplateTechApiResponse,
      GetProductTemplateTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/template`,
        params: {
          gtin: queryArg.gtin,
          packageType: queryArg.packageType,
          tnved: queryArg.tnved,
          category: queryArg.category,
          inn: queryArg.inn,
        },
      }),
    }),
    getEnumValueTech: build.query<
      GetEnumValueTechApiResponse,
      GetEnumValueTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/enum-value`,
        params: {
          enumName: queryArg.enumName,
          enumCode: queryArg.enumCode,
          language: queryArg.language,
        },
      }),
    }),
    getCategoryTech: build.query<
      GetCategoryTechApiResponse,
      GetCategoryTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/category/code`,
        params: { tnved: queryArg.tnved, skp: queryArg.skp },
      }),
    }),
    getEnumValueByAttributeValueTech: build.query<
      GetEnumValueByAttributeValueTechApiResponse,
      GetEnumValueByAttributeValueTechApiArg
    >({
      query: (queryArg) => ({
        url: `/tech/api/v1/product/attribute-enum-value`,
        params: {
          attributeCode: queryArg.attributeCode,
          attributeValues: queryArg.attributeValues,
          language: queryArg.language,
        },
      }),
    }),
    getAllTypicalComments: build.query<
      GetAllTypicalCommentsApiResponse,
      GetAllTypicalCommentsApiArg
    >({
      query: () => ({ url: `/api/v1/typical_comments` }),
    }),
    getTasks: build.query<GetTasksApiResponse, GetTasksApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/task`,
        params: {
          offset: queryArg.offset,
          assignedUserId: queryArg.assignedUserId,
          participantInn: queryArg.participantInn,
          gtin: queryArg.gtin,
          productGroupCode: queryArg.productGroupCode,
          category: queryArg.category,
          status: queryArg.status,
          dateCreateFrom: queryArg.dateCreateFrom,
          dateCreateTo: queryArg.dateCreateTo,
          limit: queryArg.limit,
        },
      }),
    }),
    getTaskById: build.query<GetTaskByIdApiResponse, GetTaskByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/task/${queryArg.id}` }),
    }),
    getTaskReport: build.query<GetTaskReportApiResponse, GetTaskReportApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/task/report`,
        params: {
          type: queryArg['type'],
          taskCreatedDateFrom: queryArg.taskCreatedDateFrom,
          taskCreatedDateTo: queryArg.taskCreatedDateTo,
          assigneeIds: queryArg.assigneeIds,
        },
      }),
    }),
    updateTasksAssigners: build.query<
      UpdateTasksAssignersApiResponse,
      UpdateTasksAssignersApiArg
    >({
      query: () => ({ url: `/api/v1/task/assigners/dropped` }),
    }),
    getProductSignData: build.query<
      GetProductSignDataApiResponse,
      GetProductSignDataApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/product/${queryArg.id}/signdata` }),
    }),
    editPublishedProduct: build.query<
      EditPublishedProductApiResponse,
      EditPublishedProductApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/product/${queryArg.id}/edit` }),
    }),
    getProductModerationResult: build.query<
      GetProductModerationResultApiResponse,
      GetProductModerationResultApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/product/${queryArg.id}/comments` }),
    }),
    getProductTemplate: build.query<
      GetProductTemplateApiResponse,
      GetProductTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product/template`,
        params: {
          gtin: queryArg.gtin,
          packageType: queryArg.packageType,
          tnved: queryArg.tnved,
          category: queryArg.category,
        },
      }),
    }),
    getProductNameSuggestion: build.query<
      GetProductNameSuggestionApiResponse,
      GetProductNameSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product/suggest-name`,
        params: {
          offset: queryArg.offset,
          status: queryArg.status,
          productName: queryArg.productName,
          language: queryArg.language,
          limit: queryArg.limit,
        },
      }),
    }),
    findProductByGtin: build.query<
      FindProductByGtinApiResponse,
      FindProductByGtinApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product/search`,
        params: {
          gtins: queryArg.gtins,
          status: queryArg.status,
          inn: queryArg.inn,
          language: queryArg.language,
        },
      }),
    }),
    migrationErrors: build.query<
      MigrationErrorsApiResponse,
      MigrationErrorsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/product/migration/errors/${queryArg.gtin}`,
      }),
    }),
    getGtinStats: build.query<GetGtinStatsApiResponse, GetGtinStatsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/product/gtin/stats`,
        params: {
          productGroupAliases: queryArg.productGroupAliases,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
        },
      }),
    }),
    getPrefixSuggestion: build.query<
      GetPrefixSuggestionApiResponse,
      GetPrefixSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/prefixes/suggest`,
        params: {
          prefix: queryArg.prefix,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getMyPrefixes: build.query<GetMyPrefixesApiResponse, GetMyPrefixesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/prefixes/my`,
        params: {
          orderDirection: queryArg.orderDirection,
          prefixStatus: queryArg.prefixStatus,
        },
      }),
    }),
    getProductGroups: build.query<
      GetProductGroupsApiResponse,
      GetProductGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pg`,
        params: {
          orderBy: queryArg.orderBy,
          orderDirection: queryArg.orderDirection,
          status: queryArg.status,
          name: queryArg.name,
          sortLanguage: queryArg.sortLanguage,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getProductGroup: build.query<
      GetProductGroupApiResponse,
      GetProductGroupApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/pg/${queryArg.code}` }),
    }),
    getAllTnvedCodesByProductGroup: build.query<
      GetAllTnvedCodesByProductGroupApiResponse,
      GetAllTnvedCodesByProductGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pg/${queryArg.code}/tnved`,
        params: { sortLanguage: queryArg.sortLanguage },
      }),
    }),
    getProductGroupPackageTypes: build.query<
      GetProductGroupPackageTypesApiResponse,
      GetProductGroupPackageTypesApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/pg/${queryArg.code}/packages` }),
    }),
    getProductCategoriesByCode: build.query<
      GetProductCategoriesByCodeApiResponse,
      GetProductCategoriesByCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pg/${queryArg.code}/category`,
        params: {
          status: queryArg.status,
          sortLanguage: queryArg.sortLanguage,
        },
      }),
    }),
    getProductGroupSuggestByName: build.query<
      GetProductGroupSuggestByNameApiResponse,
      GetProductGroupSuggestByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pg/suggest-name`,
        params: { name: queryArg.name, language: queryArg.language },
      }),
    }),
    moderatorUserSuggest: build.query<
      ModeratorUserSuggestApiResponse,
      ModeratorUserSuggestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/moderator/user/suggest`,
        params: {
          fullName: queryArg.fullName,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    getEnums: build.query<GetEnumsApiResponse, GetEnumsApiArg>({
      query: () => ({ url: `/api/v1/enum` }),
    }),
    getEnum: build.query<GetEnumApiResponse, GetEnumApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/enum/${queryArg.name}`,
        params: { sortLanguage: queryArg.sortLanguage },
      }),
    }),
    getEnumValueSuggestion: build.query<
      GetEnumValueSuggestionApiResponse,
      GetEnumValueSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/enum/${queryArg.name}/suggest-value`,
        params: {
          value: queryArg.value,
          lang: queryArg.lang,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getTnveds: build.query<GetTnvedsApiResponse, GetTnvedsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/classifier/tnved`,
        params: {
          orderBy: queryArg.orderBy,
          orderDirection: queryArg.orderDirection,
          name: queryArg.name,
          codeFrom: queryArg.codeFrom,
          codeTo: queryArg.codeTo,
          validityFrom: queryArg.validityFrom,
          validityTo: queryArg.validityTo,
          offset: queryArg.offset,
          acceptable: queryArg.acceptable,
          productGroup: queryArg.productGroup,
          limit: queryArg.limit,
        },
      }),
    }),
    getTnved: build.query<GetTnvedApiResponse, GetTnvedApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/classifier/tnved/${queryArg.code}`,
      }),
    }),
    getTnvedNameSuggestion: build.query<
      GetTnvedNameSuggestionApiResponse,
      GetTnvedNameSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/classifier/tnved/suggest-name`,
        params: {
          name: queryArg.name,
          lang: queryArg.lang,
          productGroup: queryArg.productGroup,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getTnvedCodeSuggestion: build.query<
      GetTnvedCodeSuggestionApiResponse,
      GetTnvedCodeSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/classifier/tnved/suggest-code`,
        params: {
          code: queryArg.code,
          productGroup: queryArg.productGroup,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getPublishedTnveds: build.query<
      GetPublishedTnvedsApiResponse,
      GetPublishedTnvedsApiArg
    >({
      query: () => ({ url: `/api/v1/classifier/tnved/published` }),
    }),
    getNationals: build.query<GetNationalsApiResponse, GetNationalsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/classifier/national`,
        params: {
          orderBy: queryArg.orderBy,
          orderDirection: queryArg.orderDirection,
          name: queryArg.name,
          validityFrom: queryArg.validityFrom,
          validityTo: queryArg.validityTo,
        },
      }),
    }),
    getNational: build.query<GetNationalApiResponse, GetNationalApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/classifier/national/${queryArg.code}`,
      }),
    }),
    getProductCategories: build.query<
      GetProductCategoriesApiResponse,
      GetProductCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category`,
        params: {
          orderBy: queryArg.orderBy,
          orderDirection: queryArg.orderDirection,
          status: queryArg.status,
          name: queryArg.name,
          sortLanguage: queryArg.sortLanguage,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getProductCategory: build.query<
      GetProductCategoryApiResponse,
      GetProductCategoryApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/category/${queryArg.code}` }),
    }),
    getAllTnvedCodesByCategory: build.query<
      GetAllTnvedCodesByCategoryApiResponse,
      GetAllTnvedCodesByCategoryApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/category/${queryArg.code}/tnved` }),
    }),
    getAllSkpCodes: build.query<
      GetAllSkpCodesApiResponse,
      GetAllSkpCodesApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/category/${queryArg.code}/skp` }),
    }),
    getProductCategorySuggestByName: build.query<
      GetProductCategorySuggestByNameApiResponse,
      GetProductCategorySuggestByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/category/suggest-name`,
        params: {
          name: queryArg.name,
          language: queryArg.language,
          limit: queryArg.limit,
          offset: queryArg.offset,
          productGroup: queryArg.productGroup,
        },
      }),
    }),
    getAttributes: build.query<GetAttributesApiResponse, GetAttributesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/attribute`,
        params: {
          productGroup: queryArg.productGroup,
          category: queryArg.category,
          packageType: queryArg.packageType,
          productScope: queryArg.productScope,
        },
      }),
    }),
    getAttribute: build.query<GetAttributeApiResponse, GetAttributeApiArg>({
      query: (queryArg) => ({ url: `/api/v1/attribute/${queryArg.name}` }),
    }),
    getAttributeIdentifying: build.query<
      GetAttributeIdentifyingApiResponse,
      GetAttributeIdentifyingApiArg
    >({
      query: () => ({ url: `/api/v1/attribute/identifying` }),
    }),
    getOrganizationsSuggestedByName: build.query<
      GetOrganizationsSuggestedByNameApiResponse,
      GetOrganizationsSuggestedByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations/suggest-name`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          name: queryArg.name,
          language: queryArg.language,
        },
      }),
    }),
    getOrganizationsSuggestedByInn: build.query<
      GetOrganizationsSuggestedByInnApiResponse,
      GetOrganizationsSuggestedByInnApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/admin/organizations/suggest-inn`,
        params: {
          limit: queryArg.limit,
          offset: queryArg.offset,
          inn: queryArg.inn,
        },
      }),
    }),
    deleteTaskComment: build.mutation<
      DeleteTaskCommentApiResponse,
      DeleteTaskCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/task/${queryArg.taskId}/comments/${queryArg.commentId}`,
        method: 'DELETE',
      }),
    }),
    removeFiles: build.mutation<RemoveFilesApiResponse, RemoveFilesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/product/${queryArg.id}/file`,
        method: 'DELETE',
        params: { names: queryArg.names },
      }),
    }),
    removeFile: build.mutation<RemoveFileApiResponse, RemoveFileApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/product/${queryArg.id}/file/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    deletePrefix: build.mutation<DeletePrefixApiResponse, DeletePrefixApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/prefixes/${queryArg.prefix}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiGenerated };
export type SaveProductDraftTechApiResponse = unknown;
export type SaveProductDraftTechApiArg = {
  id: string;
  body: {
    [key: string]: object;
  };
};
export type GetTaskCommentsApiResponse =
  /** status 200 OK */ ProductAttributeCommentResponse[];
export type GetTaskCommentsApiArg = {
  taskId: number;
};
export type SaveTaskCommentsApiResponse =
  /** status 200 OK */ ProductAttributeCommentResponse[];
export type SaveTaskCommentsApiArg = {
  taskId: number;
  body: ProductAttributeCommentRequest[];
};
export type UpdateTaskStatusApiResponse = /** status 200 OK */ TaskResponse;
export type UpdateTaskStatusApiArg = {
  id: number;
  status: 'CREATED' | 'IN_PROGRESS' | 'DONE_ACCEPTED' | 'DONE_REJECTED';
};
export type GetProductApiResponse = /** status 200 OK */ ProductResponse;
export type GetProductApiArg = {
  id: string;
};
export type SaveProductDraftApiResponse = unknown;
export type SaveProductDraftApiArg = {
  id: string;
  productUpdateRequest: ProductUpdateRequest;
};
export type DeleteProductApiResponse = unknown;
export type DeleteProductApiArg = {
  id: string;
};
export type GetPrefixesApiResponse = /** status 200 OK */ JointPrefixResponse[];
export type GetPrefixesApiArg = {
  /** Сортировка по полям */
  orderBy?: 'PREFIX' | 'INN';
  /** Направление сортировки */
  orderDirection?: 'ASC' | 'DESC';
  /** Признак доступности префикса для использования УОТ в РМТ */
  status?: 'ALLOWED' | 'FORBIDDEN';
  /** Частичный фильтр по префиксу */
  prefix?: string;
  /** Частичный фильтр по инн организации */
  inn?: string;
  /** Частичный фильтр по названию организации */
  name?: string;
  /** Количество получаемых записей при выборке */
  limit?: number;
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
};
export type AddPrefixApiResponse = unknown;
export type AddPrefixApiArg = {
  body: string;
};
export type EditProductPublishedApiResponse = /** status 200 OK */ object;
export type EditProductPublishedApiArg = {
  id: string;
  productOperatorUpdateRequest: ProductOperatorUpdateRequest;
};
export type GetOrganizationApiResponse = /** status 200 OK */ OrganizationFull;
export type GetOrganizationApiArg = {
  /** Числовая строка длиной до 14 символов. ИНН организации */
  inn: string;
};
export type AutoUpdateOrganizationApiResponse =
  /** status 200 OK */ OrganizationFull;
export type AutoUpdateOrganizationApiArg = {
  inn: string;
};
export type CreateProductTechApiResponse = /** status 200 OK */ ProductIdDto;
export type CreateProductTechApiArg = {
  productCreateInternalRequest: ProductCreateInternalRequest;
};
export type UploadFilesTechApiResponse =
  /** status 200 OK */ UploadedFilesRespDto;
export type UploadFilesTechApiArg = {
  id: string;
  body: {
    'meta-data': UploadProductFilesMetadataReq;
    file: Blob[];
  };
};
export type LoadOrganizationsDataTechApiResponse =
  /** status 200 OK */ string[];
export type LoadOrganizationsDataTechApiArg = {
  body: string[];
};
export type AddOrganizationPrefixesTechApiResponse = unknown;
export type AddOrganizationPrefixesTechApiArg = {
  inn: string;
  body: string[];
};
export type ImportGtinFromGs1TechApiResponse = unknown;
export type ImportGtinFromGs1TechApiArg = {
  gs1ProductReqDto: Gs1ProductReqDto;
};
export type GetProductsApiResponse =
  /** status 200 OK */ ProductShortResponse[];
export type GetProductsApiArg = {
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  orderBy?: 'UPDATED' | 'PRODUCT_NAME';
  orderDirection?: 'ASC' | 'DESC';
  status?: 'DRAFT' | 'ON_MODERATION' | 'PUBLISHED' | 'BLOCKED';
  gtin?: string;
  techGtin?: boolean;
  tnved?: string;
  productGroup?: string;
  productCategory?: string;
  packageType?: 'TRADE_UNIT' | 'INNER_PACK';
  inn?: string;
  productName?: string;
  languageComplete?: string;
  sortLanguage?: string;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
};
export type CreateProductApiResponse = /** status 200 OK */ ProductIdDto;
export type CreateProductApiArg = {
  productCreateRequest: ProductCreateRequest;
};
export type TransferProductApiResponse = /** status 200 OK */ TransferResponse;
export type TransferProductApiArg = {
  body: string[];
};
export type UploadFilesApiResponse = /** status 200 OK */ {
  [key: string]: UploadedFileMetadata;
};
export type UploadFilesApiArg = {
  body: {
    'meta-data': Blob;
    file: Blob[];
  };
};
export type GetAllPrefixesApiResponse =
  /** status 200 OK */ JointPrefixResponse[];
export type GetAllPrefixesApiArg = {
  /** Сортировка по полям */
  orderBy?: 'PREFIX' | 'INN';
  /** Направление сортировки */
  orderDirection?: 'ASC' | 'DESC';
  /** Признак доступности префикса для использования УОТ в РМТ */
  status?: 'ALLOWED' | 'FORBIDDEN';
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  limit?: number;
  prefixFilterDto: PrefixFilterDto;
};
export type SendProductToIntegrationApiResponse = /** status 200 OK */ object;
export type SendProductToIntegrationApiArg = {
  sendToIntegrationReqDto: SendToIntegrationReqDto;
};
export type GetOrganizationsApiResponse =
  /** status 200 OK */ OrganizationItem[];
export type GetOrganizationsApiArg = {
  /** Сортировка по наименованию с выбранными переводом */
  sortLanguage?: string;
  /** Сортировка по полям */
  orderBy?: 'INN' | 'STATUS' | 'NAME';
  /** Направление сортировки */
  orderDirection?: 'ASC' | 'DESC';
  /** Признак блокировки УОТ в РМТ */
  status?: 'FULL_ACTIVE' | 'ACTIVE' | 'INACTIVE';
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  limit?: number;
  inn?: string;
  name?: string;
  gs1Resident?: boolean;
};
export type FilteredOrganizationsApiResponse =
  /** status 200 OK */ OrganizationItem[];
export type FilteredOrganizationsApiArg = {
  /** Сортировка по наименованию с выбранными переводом */
  sortLanguage?: string;
  /** Сортировка по полям */
  orderBy?: 'INN' | 'STATUS' | 'NAME';
  /** Направление сортировки */
  orderDirection?: 'ASC' | 'DESC';
  /** Признак блокировки УОТ в РМТ */
  status?: 'FULL_ACTIVE' | 'ACTIVE' | 'INACTIVE';
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  limit?: number;
  organizationFilterDto: OrganizationFilterDto;
};
export type ImportOrganizationFromIsmptApiResponse = unknown;
export type ImportOrganizationFromIsmptApiArg = void;
export type ImportOrganizationFromGs1ApiResponse =
  /** status 200 OK */ OrganizationFull;
export type ImportOrganizationFromGs1ApiArg = {
  gs1OrganizationReqDto: Gs1OrganizationReqDto;
};
export type PublishProductTechApiResponse = unknown;
export type PublishProductTechApiArg = {
  id: string;
};
export type SendProductToModerationTechApiResponse = unknown;
export type SendProductToModerationTechApiArg = {
  id: string;
};
export type AssigneeTaskApiResponse = /** status 200 OK */ TaskAssigneeResponse;
export type AssigneeTaskApiArg = {
  /** ID пользователя OrgInfo, -1 для того, чтобы перевести в неназначенные */
  userId: number;
  taskAssigneeRequest: TaskAssigneeRequest;
};
export type SendProductToModerationApiResponse = unknown;
export type SendProductToModerationApiArg = {
  id: string;
  moderationReqDto: ModerationReqDto;
};
export type BlockProductApiResponse = unknown;
export type BlockProductApiArg = {
  id: string;
};
export type BlockPrefixApiResponse = unknown;
export type BlockPrefixApiArg = {
  /** Числовая строка, локальный префикс организации */
  prefix: string;
  /** ИНН организации у которой блокируется префикс, только для администратора */
  inn?: string;
  /** ИНН организации которой передаётся префикс, только для администратора */
  targetInn?: string;
  prefixDescriptionDto: PrefixDescriptionDto;
};
export type UpdateOrganizationNamesApiResponse = unknown;
export type UpdateOrganizationNamesApiArg = {
  inn: string;
  body: {
    [key: string]: string;
  };
};
export type BlockOrganizationByInnApiResponse = unknown;
export type BlockOrganizationByInnApiArg = {
  inn: string;
};
export type ActivateOrganizationApiResponse = unknown;
export type ActivateOrganizationApiArg = {
  inn: string;
};
export type EditProductPublishedTechApiResponse =
  /** status 200 OK */ ProductResponse;
export type EditProductPublishedTechApiArg = {
  id: string;
};
export type GetProductTemplateTechApiResponse =
  /** status 200 OK */ ProductResponse;
export type GetProductTemplateTechApiArg = {
  gtin?: string;
  packageType?: 'TRADE_UNIT' | 'INNER_PACK';
  tnved: string;
  category: string;
  inn: string;
};
export type GetEnumValueTechApiResponse = /** status 200 OK */ string;
export type GetEnumValueTechApiArg = {
  enumName: string;
  enumCode: string;
  language: string;
};
export type GetCategoryTechApiResponse = unknown;
export type GetCategoryTechApiArg = {
  tnved: string;
  skp: string;
};
export type GetEnumValueByAttributeValueTechApiResponse =
  /** status 200 OK */ string[];
export type GetEnumValueByAttributeValueTechApiArg = {
  attributeCode: string;
  attributeValues: Base64String[];
  language: string;
};
export type GetAllTypicalCommentsApiResponse =
  /** status 200 OK */ TypicalCommentResponse[];
export type GetAllTypicalCommentsApiArg = void;
export type GetTasksApiResponse =
  /** status 200 OK */ LimitedResponseTaskResponse;
export type GetTasksApiArg = {
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  /** ID пользователя OrgInfo, -1 для того, чтобы выбрать не назначенные */
  assignedUserId?: number;
  participantInn?: string;
  gtin?: string;
  productGroupCode?: string;
  category?: string;
  status?: ('CREATED' | 'IN_PROGRESS' | 'DONE_ACCEPTED' | 'DONE_REJECTED')[];
  /** Дата начала периода (включительно) */
  dateCreateFrom?: string;
  /** Дата окончания периода (включительно) */
  dateCreateTo?: string;
  limit?: number;
};
export type GetTaskByIdApiResponse = /** status 200 OK */ TaskResponse;
export type GetTaskByIdApiArg = {
  id: number;
};
export type GetTaskReportApiResponse = /** status 200 OK */ object;
export type GetTaskReportApiArg = {
  type: 'DETAIL' | 'SUMMARY';
  taskCreatedDateFrom?: string;
  taskCreatedDateTo?: string;
  assigneeIds?: number[];
};
export type UpdateTasksAssignersApiResponse =
  /** status 200 OK */ TaskResponse[];
export type UpdateTasksAssignersApiArg = void;
export type GetProductSignDataApiResponse = /** status 200 OK */ string;
export type GetProductSignDataApiArg = {
  id: string;
};
export type EditPublishedProductApiResponse =
  /** status 200 OK */ ProductResponse;
export type EditPublishedProductApiArg = {
  id: string;
};
export type GetProductModerationResultApiResponse =
  /** status 200 OK */ ProductModerationResponse;
export type GetProductModerationResultApiArg = {
  id: string;
};
export type GetProductTemplateApiResponse =
  /** status 200 OK */ ProductResponse;
export type GetProductTemplateApiArg = {
  gtin?: string;
  packageType?: 'TRADE_UNIT' | 'INNER_PACK';
  tnved: string;
  category: string;
};
export type GetProductNameSuggestionApiResponse = /** status 200 OK */ IdName[];
export type GetProductNameSuggestionApiArg = {
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  status: 'DRAFT' | 'ON_MODERATION' | 'PUBLISHED' | 'BLOCKED';
  productName: string;
  language?: string;
  limit?: number;
};
export type FindProductByGtinApiResponse = /** status 200 OK */ ProductDto[];
export type FindProductByGtinApiArg = {
  gtins: string[];
  status?: 'DRAFT' | 'ON_MODERATION' | 'PUBLISHED' | 'BLOCKED';
  inn?: string;
  language?: string;
};
export type MigrationErrorsApiResponse = /** status 200 OK */ Translated[];
export type MigrationErrorsApiArg = {
  gtin: string;
};
export type GetGtinStatsApiResponse = /** status 200 OK */ GtinStatsResponse[];
export type GetGtinStatsApiArg = {
  /** Список псевдонимов ТГ */
  productGroupAliases?: string[];
  /** Дата начала периода (включительно) */
  dateFrom?: string;
  /** Дата окончания периода (включительно) */
  dateTo?: string;
};
export type GetPrefixSuggestionApiResponse =
  /** status 200 OK */ JointPrefixResponse[];
export type GetPrefixSuggestionApiArg = {
  /** Часть префикса УОТ */
  prefix: string;
  /** Размер страницы выборки */
  limit?: number;
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
};
export type GetMyPrefixesApiResponse =
  /** status 200 OK */ JointPrefixResponse[];
export type GetMyPrefixesApiArg = {
  /** Направление сортировки */
  orderDirection?: 'ASC' | 'DESC';
  /** Признак доступности префикса для использования УОТ в РМТ */
  prefixStatus?: 'ALLOWED' | 'FORBIDDEN';
};
export type GetProductGroupsApiResponse =
  /** status 200 OK */ ProductGroupResponse[];
export type GetProductGroupsApiArg = {
  orderBy?: 'ID' | 'NAME';
  orderDirection?: 'ASC' | 'DESC';
  status?: 'DRAFT' | 'PUBLISHED' | 'BLOCKED';
  name?: string;
  sortLanguage?: string;
  limit?: number;
  offset?: number;
};
export type GetProductGroupApiResponse =
  /** status 200 OK */ ProductGroupResponse;
export type GetProductGroupApiArg = {
  code: string;
};
export type GetAllTnvedCodesByProductGroupApiResponse =
  /** status 200 OK */ ProductGroupTnvedResponse;
export type GetAllTnvedCodesByProductGroupApiArg = {
  code: string;
  sortLanguage?: string;
};
export type GetProductGroupPackageTypesApiResponse = /** status 200 OK */ (
  | 'TRADE_UNIT'
  | 'INNER_PACK'
)[];
export type GetProductGroupPackageTypesApiArg = {
  code: string;
};
export type GetProductCategoriesByCodeApiResponse =
  /** status 200 OK */ ProductCategoryResponse[];
export type GetProductCategoriesByCodeApiArg = {
  status?: 'DRAFT' | 'PUBLISHED' | 'BLOCKED';
  code: string;
  sortLanguage?: string;
};
export type GetProductGroupSuggestByNameApiResponse =
  /** status 200 OK */ ProductGroupResponse[];
export type GetProductGroupSuggestByNameApiArg = {
  /** Часть наименования ТГ */
  name: string;
  /** Язык поиска и сортировки */
  language?: string;
};
export type ModeratorUserSuggestApiResponse =
  /** status 200 OK */ UserProxyDto[];
export type ModeratorUserSuggestApiArg = {
  fullName?: string;
  page?: number;
  size?: number;
};
export type GetEnumsApiResponse = /** status 200 OK */ EnumerationNameDto[];
export type GetEnumsApiArg = void;
export type GetEnumApiResponse = /** status 200 OK */ EnumerationDto;
export type GetEnumApiArg = {
  name: string;
  sortLanguage?: string;
};
export type GetEnumValueSuggestionApiResponse = /** status 200 OK */ Value[];
export type GetEnumValueSuggestionApiArg = {
  /** Название перечисления */
  name: string;
  /** Часть имени записи справочника */
  value: string;
  /** Язык поиска по части имени */
  lang?: string;
  /** Ограничение выбоки - размер страницы */
  limit?: number;
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
};
export type GetTnvedsApiResponse = /** status 200 OK */ TnvedClassifier[];
export type GetTnvedsApiArg = {
  /** Сортировка по полям */
  orderBy?: 'CODE' | 'NAME' | 'VALIDITY_DATE';
  /** Направление сортировки */
  orderDirection?: 'ASC' | 'DESC';
  /** Текстовая строка поиска по имени ТНВЭД */
  name?: string;
  /** Числовая строка длиной в 10 символов. Начало диапазона кодов ТНВЭД для поиска 'от' */
  codeFrom?: string;
  /** Числовая строка длиной в 10 символов. Конец диапазона кодов ТНВЭД для поиска 'до' */
  codeTo?: string;
  /** Дата в формате yyyy-MM-dd (без времени). Диапазон сроков действия кодов ТНВЭД для поиска 'от' */
  validityFrom?: string;
  /** Дата в формате yyyy-MM-dd (без времени). Диапазон сроков действия кодов ТНВЭД для поиска 'до' */
  validityTo?: string;
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  acceptable?: boolean;
  productGroup?: string;
  limit?: number;
};
export type GetTnvedApiResponse = /** status 200 OK */ TnvedClassifier;
export type GetTnvedApiArg = {
  /** Код ТНВЭД. Числовая строка длиной в 10 символов. */
  code: string;
};
export type GetTnvedNameSuggestionApiResponse =
  /** status 200 OK */ TnvedClassifier[];
export type GetTnvedNameSuggestionApiArg = {
  /** Часть наименования кода ТНВЭД */
  name: string;
  /** Язык поиска */
  lang?: string;
  /** Товарная группа для поиска кодов */
  productGroup?: string;
  /** Ограничение выборки - размер страницы */
  limit?: number;
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
};
export type GetTnvedCodeSuggestionApiResponse =
  /** status 200 OK */ TnvedClassifier[];
export type GetTnvedCodeSuggestionApiArg = {
  /** Часть кода ТНВЭД */
  code: string;
  /** Товарная группа для поиска кодов */
  productGroup?: string;
  /** Ограничение выборки - размер страницы */
  limit?: number;
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
};
export type GetPublishedTnvedsApiResponse =
  /** status 200 OK */ TnvedClassifier[];
export type GetPublishedTnvedsApiArg = void;
export type GetNationalsApiResponse = /** status 200 OK */ NationalClassifier[];
export type GetNationalsApiArg = {
  /** Сортировка по полям */
  orderBy?: 'CODE' | 'NAME' | 'VALIDITY_DATE';
  /** Направление сортировки */
  orderDirection?: 'ASC' | 'DESC';
  /** Текстовая строка поиска по имени */
  name?: string;
  /** Дата в формате yyyy-MM-dd (без времени). Диапазон сроков действия кодов ТНВЭД для поиска 'от' */
  validityFrom?: string;
  /** Дата в формате yyyy-MM-dd (без времени). Диапазон сроков действия кодов ТНВЭД для поиска 'до' */
  validityTo?: string;
};
export type GetNationalApiResponse = /** status 200 OK */ NationalClassifier;
export type GetNationalApiArg = {
  code: string;
};
export type GetProductCategoriesApiResponse =
  /** status 200 OK */ ProductCategoryResponse[];
export type GetProductCategoriesApiArg = {
  orderBy?: 'CODE' | 'NAME';
  orderDirection?: 'ASC' | 'DESC';
  status?: 'DRAFT' | 'PUBLISHED' | 'BLOCKED';
  name?: string;
  sortLanguage?: string;
  limit?: number;
  offset?: number;
};
export type GetProductCategoryApiResponse =
  /** status 200 OK */ ProductCategoryFullResponse;
export type GetProductCategoryApiArg = {
  code: string;
};
export type GetAllTnvedCodesByCategoryApiResponse =
  /** status 200 OK */ ProductCategoryTnvedResponse;
export type GetAllTnvedCodesByCategoryApiArg = {
  code: string;
};
export type GetAllSkpCodesApiResponse = /** status 200 OK */ string[];
export type GetAllSkpCodesApiArg = {
  code: string;
};
export type GetProductCategorySuggestByNameApiResponse =
  /** status 200 OK */ ProductCategoryResponse[];
export type GetProductCategorySuggestByNameApiArg = {
  /** Часть наименования категории */
  name: string;
  /** Язык поиска и сортировки */
  language?: string;
  /** Ограничение выборки - размер страницы */
  limit?: number;
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  productGroup?: string;
};
export type GetAttributesApiResponse = /** status 200 OK */ AttributeResponse[];
export type GetAttributesApiArg = {
  productGroup?: string;
  category?: string;
  packageType?: 'TRADE_UNIT' | 'INNER_PACK' | 'ANY';
  productScope?: 'LOCAL' | 'GLOBAL' | 'TECHNICAL' | 'ANY';
};
export type GetAttributeApiResponse = /** status 200 OK */ AttributeResponse;
export type GetAttributeApiArg = {
  name: string;
};
export type GetAttributeIdentifyingApiResponse =
  /** status 200 OK */ AttributeIdentifyingResponse[];
export type GetAttributeIdentifyingApiArg = void;
export type GetOrganizationsSuggestedByNameApiResponse =
  /** status 200 OK */ OrganizationSuggest[];
export type GetOrganizationsSuggestedByNameApiArg = {
  /** Размер страницы */
  limit?: number;
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  /** Часть имени УОТ */
  name: string;
  /** Язык поиска */
  language?: string;
};
export type GetOrganizationsSuggestedByInnApiResponse =
  /** status 200 OK */ OrganizationSuggest[];
export type GetOrganizationsSuggestedByInnApiArg = {
  /** Размер страницы */
  limit?: number;
  /** Величина смещения при выборке записей (количество записей, которые необходимо пропустить) */
  offset?: number;
  /** ИНН или его часть */
  inn: string;
};
export type DeleteTaskCommentApiResponse = unknown;
export type DeleteTaskCommentApiArg = {
  taskId: number;
  commentId: number;
};
export type RemoveFilesApiResponse = /** status 200 OK */ DeleteFilesRespDto;
export type RemoveFilesApiArg = {
  id: string;
  names: string[];
};
export type RemoveFileApiResponse = unknown;
export type RemoveFileApiArg = {
  id: string;
  name: string;
};
export type DeletePrefixApiResponse = unknown;
export type DeletePrefixApiArg = {
  /** Числовая строка, локальный префикс организации */
  prefix: string;
};
export type TypicalCommentResponse = {
  id: number;
  value: {
    [key: string]: string;
  };
};
export type ProductAttributeCommentResponse = {
  id: number;
  attribute: string;
  lang?: string;
  value: string;
  customComment?: string;
  typicalComment?: TypicalCommentResponse;
  timestamp: string;
  userId: number;
};
export type ProductAttributeCommentRequest = {
  attribute: string;
  lang: string;
  value: object;
  customComment?: string;
  typicalCommentId?: number;
};
export type OrganizationShort = {
  inn: string;
  name: {
    [key: string]: string;
  };
  status: 'FULL_ACTIVE' | 'ACTIVE' | 'INACTIVE';
  source: 'ISMPT' | 'GS1_UZ' | 'NC';
  status_ext?: 'ACTIVE' | 'CANCELLED' | 'SUSPENDED';
  gs1_active?: boolean;
};
export type ProductGroupsStatusResponse = {
  code: 'DRAFT' | 'PUBLISHED' | 'BLOCKED';
  name: {
    [key: string]: string;
  };
};
export type ProductGroupResponse = {
  code: string;
  id: number;
  name: {
    [key: string]: string;
  };
  status: ProductGroupsStatusResponse;
  moderation: 'AUTO' | 'MANUAL';
  packages: ('TRADE_UNIT' | 'INNER_PACK')[];
  gtinsCount?: number;
};
export type Product = {
  category_name: {
    [key: string]: string;
  };
  product_group: ProductGroupResponse;
  id: string;
  name: {
    [key: string]: object;
  };
};
export type AssignedUser = {
  full_name: string;
  id: number;
};
export type TaskResponse = {
  id: number;
  participant: OrganizationShort;
  gtin: string;
  product: Product;
  status: 'CREATED' | 'IN_PROGRESS' | 'DONE_ACCEPTED' | 'DONE_REJECTED';
  assigned_user?: AssignedUser;
  date_create: string;
  date_finish?: string;
};
export type CodeName = {
  code: string;
  name: {
    [key: string]: string;
  };
};
export type AttributeConstraints = {
  min?: string;
  max?: string;
  regexp?: string;
  reference?: string;
  units?: string[];
  range?: string[];
};
export type Attribute = {
  code?: string;
  name?: {
    [key: string]: string;
  };
  type?:
    | 'BOOLEAN'
    | 'ARRAY'
    | 'STRING'
    | 'STRING_LOCALIZED'
    | 'INTEGER'
    | 'ENUM'
    | 'DOUBLE'
    | 'DATE'
    | 'FILE_LIST'
    | 'INNER_GTIN'
    | 'LOCALIZED_LIST'
    | 'ENUM_LIST';
  scope?: 'COMMON' | 'EXTRA' | 'SYSTEM';
  constraints?: AttributeConstraints;
  group?: string;
  group_order?: number;
  product_groups?: {
    [key: string]: 'MANDATORY' | 'OPTIONAL';
  };
  product_categories?: {
    [key: string]: 'MANDATORY' | 'OPTIONAL';
  };
  package_types?: {
    [key: string]: 'MANDATORY' | 'OPTIONAL';
  };
  product_scopes?: {
    [key: string]: 'MANDATORY' | 'OPTIONAL';
  };
  legal?: 'SIGNIFICANT' | 'INSIGNIFICANT';
  help_path?: string;
};
export type Asset = {
  edit_mode?: {
    [key: string]: 'EDITABLE' | 'NOT_EDITABLE';
  };
  attribute?: Attribute;
  usageMode?: 'MANDATORY' | 'OPTIONAL';
  value?: object;
  ikpuAttribute?: boolean;
};
export type ProductAttributeConstraints = {
  min?: string;
  max?: string;
  regexp?: string;
  range?: string[];
};
export type AttributeResponseConstraints = {
  min?: string;
  max?: string;
  regexp?: string;
  reference?: string;
  units?: {
    [key: string]: ProductAttributeConstraints;
  };
};
export type AttributeResponse = {
  code: string;
  name: {
    [key: string]: string;
  };
  type:
    | 'BOOLEAN'
    | 'ARRAY'
    | 'STRING'
    | 'STRING_LOCALIZED'
    | 'INTEGER'
    | 'ENUM'
    | 'DOUBLE'
    | 'DATE'
    | 'FILE_LIST'
    | 'INNER_GTIN'
    | 'LOCALIZED_LIST'
    | 'ENUM_LIST';
  scope: 'COMMON' | 'EXTRA' | 'SYSTEM';
  constraints: AttributeResponseConstraints;
  group: string;
  group_order: number;
  product_groups: {
    [key: string]: 'MANDATORY' | 'OPTIONAL';
  };
  categories: {
    [key: string]: 'MANDATORY' | 'OPTIONAL';
  };
  package_types: {
    [key: string]: 'MANDATORY' | 'OPTIONAL';
  };
  product_scopes: {
    [key: string]: 'MANDATORY' | 'OPTIONAL';
  };
  legal: 'SIGNIFICANT' | 'INSIGNIFICANT';
  unit: string;
  help_path?: string;
};
export type AssetResponse = {
  edit_mode: {
    [key: string]: 'EDITABLE' | 'NOT_EDITABLE';
  };
  attribute: AttributeResponse;
  usageMode: 'MANDATORY' | 'OPTIONAL';
  value?: object;
};
export type ProductResponse = {
  required_languages: string[];
  product_group: CodeName;
  product_category: CodeName;
  product?: Product;
  assets?: Asset[];
  attributesUnits?: {
    [key: string]: string[];
  };
  attrUnitsConstraints?: {
    [key: string]: {
      [key: string]: ProductAttributeConstraints;
    };
  };
  signer_full_name?: string;
  source: 'RMT' | 'NC';
  scope: 'LOCAL' | 'GLOBAL' | 'TECHNICAL' | 'ANY';
  status: 'DRAFT' | 'ON_MODERATION' | 'PUBLISHED' | 'BLOCKED';
  id: string;
  category: string;
  system_attributes: AssetResponse[];
  optional_attributes: AssetResponse[];
  inn: string;
  sign_date?: string;
  mandatory_attributes: AssetResponse[];
  status_change_message?: string;
  tnved: string;
  gtin: string;
  package_type: 'TRADE_UNIT' | 'INNER_PACK' | 'ANY';
  internal_id?: string;
  child_id?: string;
  parent_id?: string;
};
export type ProductUpdateRequest = {
  tnved: string;
  attributes: {
    [key: string]: object;
  };
  attributes_units: {
    [key: string]: string;
  };
};
export type JointPrefixResponse = {
  inn: string;
  name?: {
    [key: string]: string;
  };
  code: string;
  status: 'ALLOWED' | 'FORBIDDEN';
  description?: string;
};
export type ProductOperatorUpdateRequest = {
  attributes: {
    [key: string]: object;
  };
  attributes_units: {
    [key: string]: string;
  };
};
export type ProductGroupConstraints = {
  package?: ('TRADE_UNIT' | 'INNER_PACK' | 'ANY')[];
  allow_technical?: boolean;
  allow_technical_date?: string;
};
export type ProductGroup = {
  code?: string;
  id?: number;
  name?: {
    [key: string]: string;
  };
  status?: 'DRAFT' | 'PUBLISHED' | 'BLOCKED';
  moderation?: 'AUTO' | 'MANUAL';
  constraints?: ProductGroupConstraints;
};
export type OrganizationPrefix = {
  code?: string;
  status?: 'ALLOWED' | 'FORBIDDEN';
  description?: string;
};
export type OrganizationFull = {
  inn?: string;
  name?: {
    [key: string]: string;
  };
  status?: 'FULL_ACTIVE' | 'ACTIVE' | 'INACTIVE';
  product_group?: ProductGroup[];
  source?: 'ISMPT' | 'GS1_UZ' | 'NC';
  prefix?: OrganizationPrefix[];
  status_ext?: 'ACTIVE' | 'CANCELLED' | 'SUSPENDED';
  gs1_active?: boolean;
};
export type ProductIdDto = {
  id: string;
};
export type ProductCreateInternalRequest = {
  gtin: string;
  package_type: 'TRADE_UNIT' | 'INNER_PACK';
  tnved: string;
  category: string;
  inn: string;
  internal_id?: string;
  source: 'RMT' | 'NC';
  created_at?: number;
  updated_at?: number;
  attributes: {
    [key: string]: object;
  };
};
export type UploadedFileInfoDto = {
  fileId?: string;
};
export type UploadedFilesRespDto = {
  files?: {
    [key: string]: UploadedFileInfoDto;
  };
};
export type FileMetadataAttributeDto = {
  attributeName: string;
  description?: string;
};
export type UploadProductFilesMetadataReq = {
  files?: {
    [key: string]: FileMetadataAttributeDto;
  };
};
export type Gs1ProductPackageDto = {
  id: number;
  name?: string;
  measurement?: string;
  quantity?: number;
  gtin: string;
};
export type Gs1ProductReqDto = {
  id: number;
  inn: string;
  name?: string;
  gpc?: string;
  tradeMark?: string;
  gtin: string;
  quantity?: number;
  status?: '0' | '1';
  packages?: Gs1ProductPackageDto[];
};
export type TranslatedString = {
  name: {
    [key: string]: string;
  };
  value: string;
};
export type ProductShortResponse = {
  id: string;
  gtin: string;
  tnved: string;
  product_name: {
    [key: string]: string;
  };
  inn: string;
  participant_name: {
    [key: string]: string;
  };
  pg: TranslatedString;
  product_category: TranslatedString;
  package_type: TranslatedString;
  languages_complete: {
    [key: string]: boolean;
  };
  required_languages: string[];
  status_change_message?: string;
  updated_at: number;
};
export type ProductCreateRequest = {
  gtin: string;
  package_type: 'TRADE_UNIT' | 'INNER_PACK';
  tnved: string;
  category: string;
  attributes: {
    [key: string]: object;
  };
  attributes_units: {
    [key: string]: string;
  };
};
export type Failed = {
  failed_gtin?: string;
  reason?: string;
};
export type TransferResponse = {
  success: string[];
  failed: Failed[];
};
export type UploadedFileMetadata = {
  filename?: string;
  checksum?: string;
  hashType?: string;
};
export type PrefixFilterDto = {
  prefix?: string;
  inn?: string;
  name?: string;
};
export type SendToIntegrationReqDto = {
  gtins?: string[];
};
export type OrganizationItem = {
  inn?: string;
  name?: {
    [key: string]: string;
  };
  status?: 'FULL_ACTIVE' | 'ACTIVE' | 'INACTIVE';
  status_ext?: 'ACTIVE' | 'CANCELLED' | 'SUSPENDED';
  gs1_resident?: boolean;
  published?: number;
  product_groups?: number;
  prefixes?: number;
};
export type OrganizationFilterDto = {
  inn?: string;
  name?: string;
  gs1_resident?: boolean;
};
export type Gs1OrganizationReqDto = {
  inn: string;
  name?: string;
  gcp?: string[];
  status: '0' | '1' | '2';
};
export type ModerationResult = {
  code: number;
  message: string;
  taskIds: number[];
};
export type TaskAssigneeResponse = {
  results: ModerationResult[];
};
export type TaskAssigneeRequest = {
  taskIds: number[];
};
export type ModerationReqDto = {
  signature?: string;
};
export type PrefixDescriptionDto = {
  description?: string;
};
export type Base64String = {
  base64Value?: string;
  decodedValue?: string;
  decoded?: string;
};
export type LimitedResponseTaskResponse = {
  total: number;
  content: TaskResponse[];
};
export type ProductModerationResponse = {
  task: TaskResponse;
  comments?: ProductAttributeCommentResponse[];
};
export type IdName = {
  id: string;
  product_name: {
    [key: string]: string;
  };
};
export type ProductDto = {
  id: string;
  inn: string;
  gtin: string;
  tnved: string;
  status: 'DRAFT' | 'ON_MODERATION' | 'PUBLISHED' | 'BLOCKED';
  status_change_message?: string;
  package_type: 'TRADE_UNIT' | 'INNER_PACK' | 'ANY';
  scope: 'LOCAL' | 'GLOBAL' | 'TECHNICAL' | 'ANY';
  is_tech_gtin: boolean;
  created_at: number;
  updated_at?: number;
  attributes?: {
    [key: string]: object;
  };
};
export type Translated = {
  attr_name?: {
    [key: string]: string;
  };
  attr_code?: string;
  error_code?: string;
  error_description?: string;
};
export type StatsByStatus = {
  status?: 'DRAFT' | 'ON_MODERATION' | 'PUBLISHED' | 'BLOCKED';
  gtinsCount?: number;
};
export type GtinStatsResponse = {
  productGroupAlias: string;
  productsCount: number;
  uniqueGtinsCount?: number;
  byStatusCount?: StatsByStatus[];
};
export type ProductCategoryShort = {
  code?: string;
  name?: {
    [key: string]: string;
  };
};
export type TnvedClassifierWithCategories = {
  code?: string;
  name?: {
    [key: string]: string;
  };
  product_group?: string;
  validity_date?: string;
  acceptable?: boolean;
  categories?: ProductCategoryShort[];
};
export type ProductGroupTnvedResponse = {
  code?: string;
  tnved_codes?: TnvedClassifierWithCategories[];
};
export type ProductCategoriesStatusResponse = {
  code?: 'DRAFT' | 'PUBLISHED' | 'BLOCKED';
  name?: {
    [key: string]: string;
  };
};
export type ProductCategoryResponse = {
  code: string;
  name?: {
    [key: string]: string;
  };
  status: ProductCategoriesStatusResponse;
  product_group: string;
  is_tech_category: boolean;
};
export type UserProxyDto = {
  id: number;
  login: string;
  status?:
    | 'REGISTERED'
    | 'APPROVED'
    | 'LOCKED'
    | 'ACTIVE'
    | 'BLOCKED'
    | 'REMOVED'
    | 'DRAFT'
    | 'PRE_REGISTERED';
  fullName: string;
};
export type EnumerationNameDto = {
  code: string;
  translations: {
    [key: string]: string;
  };
};
export type Value = {
  code: string;
  translations: {
    [key: string]: string;
  };
};
export type EnumerationDto = {
  name: string;
  translations: {
    [key: string]: string;
  };
  values?: Value[];
};
export type TnvedClassifier = {
  code: string;
  name: {
    [key: string]: string;
  };
  product_group: string;
  validity_date?: string;
  acceptable: boolean;
};
export type NationalClassifier = {
  code?: string;
  name?: {
    [key: string]: string;
  };
  validity_date?: string;
};
export type ProductCategoryFullResponse = {
  code: string;
  product_group: string;
  name?: {
    [key: string]: string;
  };
  status: ProductCategoriesStatusResponse;
  skp_code?: string[];
  tnved_codes?: TnvedClassifier[];
  is_tech_category: boolean;
  attributes?: AssetResponse[];
};
export type ProductCategoryTnvedResponse = {
  code: string;
  tnved_codes?: TnvedClassifier[];
};
export type AttributeIdentifyingResponse = {
  attribute: AttributeResponse;
  usageMode: 'MANDATORY' | 'OPTIONAL';
};
export type OrganizationSuggest = {
  inn?: string;
  name?: {
    [key: string]: string;
  };
};
export type DeleteFilesRespDto = {
  deletedFiles?: string[];
};
export const {
  useSaveProductDraftTechMutation,
  useGetTaskCommentsQuery,
  useLazyGetTaskCommentsQuery,
  useSaveTaskCommentsMutation,
  useUpdateTaskStatusMutation,
  useGetProductQuery,
  useLazyGetProductQuery,
  useSaveProductDraftMutation,
  useDeleteProductMutation,
  useGetPrefixesQuery,
  useLazyGetPrefixesQuery,
  useAddPrefixMutation,
  useEditProductPublishedMutation,
  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,
  useAutoUpdateOrganizationMutation,
  useCreateProductTechMutation,
  useUploadFilesTechMutation,
  useLoadOrganizationsDataTechMutation,
  useAddOrganizationPrefixesTechMutation,
  useImportGtinFromGs1TechMutation,
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useCreateProductMutation,
  useTransferProductMutation,
  useUploadFilesMutation,
  useGetAllPrefixesMutation,
  useSendProductToIntegrationMutation,
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useFilteredOrganizationsMutation,
  useImportOrganizationFromIsmptMutation,
  useImportOrganizationFromGs1Mutation,
  usePublishProductTechMutation,
  useSendProductToModerationTechMutation,
  useAssigneeTaskMutation,
  useSendProductToModerationMutation,
  useBlockProductMutation,
  useBlockPrefixMutation,
  useUpdateOrganizationNamesMutation,
  useBlockOrganizationByInnMutation,
  useActivateOrganizationMutation,
  useEditProductPublishedTechQuery,
  useLazyEditProductPublishedTechQuery,
  useGetProductTemplateTechQuery,
  useLazyGetProductTemplateTechQuery,
  useGetEnumValueTechQuery,
  useLazyGetEnumValueTechQuery,
  useGetCategoryTechQuery,
  useLazyGetCategoryTechQuery,
  useGetEnumValueByAttributeValueTechQuery,
  useLazyGetEnumValueByAttributeValueTechQuery,
  useGetAllTypicalCommentsQuery,
  useLazyGetAllTypicalCommentsQuery,
  useGetTasksQuery,
  useLazyGetTasksQuery,
  useGetTaskByIdQuery,
  useLazyGetTaskByIdQuery,
  useGetTaskReportQuery,
  useLazyGetTaskReportQuery,
  useUpdateTasksAssignersQuery,
  useLazyUpdateTasksAssignersQuery,
  useGetProductSignDataQuery,
  useLazyGetProductSignDataQuery,
  useEditPublishedProductQuery,
  useLazyEditPublishedProductQuery,
  useGetProductModerationResultQuery,
  useLazyGetProductModerationResultQuery,
  useGetProductTemplateQuery,
  useLazyGetProductTemplateQuery,
  useGetProductNameSuggestionQuery,
  useLazyGetProductNameSuggestionQuery,
  useFindProductByGtinQuery,
  useLazyFindProductByGtinQuery,
  useMigrationErrorsQuery,
  useLazyMigrationErrorsQuery,
  useGetGtinStatsQuery,
  useLazyGetGtinStatsQuery,
  useGetPrefixSuggestionQuery,
  useLazyGetPrefixSuggestionQuery,
  useGetMyPrefixesQuery,
  useLazyGetMyPrefixesQuery,
  useGetProductGroupsQuery,
  useLazyGetProductGroupsQuery,
  useGetProductGroupQuery,
  useLazyGetProductGroupQuery,
  useGetAllTnvedCodesByProductGroupQuery,
  useLazyGetAllTnvedCodesByProductGroupQuery,
  useGetProductGroupPackageTypesQuery,
  useLazyGetProductGroupPackageTypesQuery,
  useGetProductCategoriesByCodeQuery,
  useLazyGetProductCategoriesByCodeQuery,
  useGetProductGroupSuggestByNameQuery,
  useLazyGetProductGroupSuggestByNameQuery,
  useModeratorUserSuggestQuery,
  useLazyModeratorUserSuggestQuery,
  useGetEnumsQuery,
  useLazyGetEnumsQuery,
  useGetEnumQuery,
  useLazyGetEnumQuery,
  useGetEnumValueSuggestionQuery,
  useLazyGetEnumValueSuggestionQuery,
  useGetTnvedsQuery,
  useLazyGetTnvedsQuery,
  useGetTnvedQuery,
  useLazyGetTnvedQuery,
  useGetTnvedNameSuggestionQuery,
  useLazyGetTnvedNameSuggestionQuery,
  useGetTnvedCodeSuggestionQuery,
  useLazyGetTnvedCodeSuggestionQuery,
  useGetPublishedTnvedsQuery,
  useLazyGetPublishedTnvedsQuery,
  useGetNationalsQuery,
  useLazyGetNationalsQuery,
  useGetNationalQuery,
  useLazyGetNationalQuery,
  useGetProductCategoriesQuery,
  useLazyGetProductCategoriesQuery,
  useGetProductCategoryQuery,
  useLazyGetProductCategoryQuery,
  useGetAllTnvedCodesByCategoryQuery,
  useLazyGetAllTnvedCodesByCategoryQuery,
  useGetAllSkpCodesQuery,
  useLazyGetAllSkpCodesQuery,
  useGetProductCategorySuggestByNameQuery,
  useLazyGetProductCategorySuggestByNameQuery,
  useGetAttributesQuery,
  useLazyGetAttributesQuery,
  useGetAttributeQuery,
  useLazyGetAttributeQuery,
  useGetAttributeIdentifyingQuery,
  useLazyGetAttributeIdentifyingQuery,
  useGetOrganizationsSuggestedByNameQuery,
  useLazyGetOrganizationsSuggestedByNameQuery,
  useGetOrganizationsSuggestedByInnQuery,
  useLazyGetOrganizationsSuggestedByInnQuery,
  useDeleteTaskCommentMutation,
  useRemoveFilesMutation,
  useRemoveFileMutation,
  useDeletePrefixMutation,
} = injectedRtkApi;
