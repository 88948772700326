import { createAction } from '../../helpers/redux';
import { types } from '../types';

export const getTnved = createAction(types.TNVED_GET_LIST);
export const setTnved = createAction(types.TNVED_SET_LIST);
export const getCategories = createAction(types.CATEGORIES_GET_LIST);
export const getTnvedByCategories = createAction(
  types.TNVED_BY_CATEGORIES_GET_LIST
);
export const getNationalClassifier = createAction(types.NATIONAL_GET_LIST);
export const setNationalClassifier = createAction(types.NATIONAL_SET_LIST);
// TODO: remove enum
export const getEnum = createAction(types.ENUM_GET_LIST);
export const setEnum = createAction(types.ENUM_SET_LIST);
export const getGtins = createAction(types.GET_GTINS);
export const getTypicalComments = createAction(types.GET_TYPICAL_COMMENTS);
