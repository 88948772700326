import React, { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';
import { setDefaultOptions } from 'date-fns';
import { enUS as en, hy, kk as kz, ru, uz } from 'date-fns/locale';

import { Header, useEvent } from '@ibox/ui';

import { routes } from '../../../routes';
import {
  Entity,
  useApplicationConfig,
} from '../../application-config/application-config';
import { getShortName } from '../../../utils/utils';
import { RootState, Language } from '../../../store/reducers';
import { logout } from '../../../store/actions/auth';
import { setCookie } from '../../../utils/cookieHelper';
import { saveLanguage } from '../../../store/actions/common';
import Logo from '../../../assets/logos/logo_am.png';
import { useEnums } from '../../../api-generated/api-hooks';

import css from './index.module.scss';

const LOCALES: Record<Language, Locale> = {
  en,
  ru,
  uz,
  kz,
  hy,
};

type SubMenuItem = {
  id: Entity;
  label: string;
  value: string;
};

const SUB_MENU_ITEMS: SubMenuItem[] = [
  {
    id: 'participants',
    label: 'Участники товарооборота',
    value: routes.participants(),
  },
  {
    id: 'prefixes',
    label: 'Префиксы GS1 (GCP)',
    value: routes.prefixes(),
  },
  {
    id: 'products',
    label: 'Товары',
    value: routes.products(),
  },
  {
    id: 'tasks',
    label: 'Модерация товаров',
    value: routes.tasks(),
  },
  {
    id: 'productGroups',
    label: 'Товарные группы',
    value: routes.productGroups(),
  },
];

const MENU_ITEMS = [
  {
    id: 1,
    selected: true,
    label: 'Реестр товаров',
    value: 'rmt',
  },
  {
    id: 2,
    selected: false,
    label: 'Маркировка товаров',
    value: 'mark',
    url: `${window.env.REACT_APP_ELK_URL}/#/dashboard`,
  },
  {
    id: 3,
    selected: false,
    label: 'Заказ кодов',
    value: 'icom',
    url: `${window.env.REACT_APP_ICOM_URL}/#/orders`,
  },
];

export const HeaderWrapper = () => {
  const user = useSelector((state: RootState) => state?.currentUser);
  const { language, languageEnum } = useEnums();
  // TODO!: we need to discuss about this change in task https://jira.ittds.tech/browse/RMT-2360
  const languages = useMemo(
    () => languageEnum.filter(({ code }) => code !== 'en'),
    [languageEnum]
  );
  const history = useHistory();
  const applicationConfig = useApplicationConfig();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const dsp = useDispatch();

  const changeLanguage = useEvent((language: Language) => {
    setDefaultOptions({ locale: LOCALES[language] });
    i18n.changeLanguage(language);
    dsp(saveLanguage(language));
    setCookie('language', language.toLowerCase());
  });

  const userName = useMemo(
    () => (!isEmpty(user) ? getShortName(user.full_name) : ''),
    [user]
  );

  const handleHistoryPush = useEvent((newValue) => history.push(newValue));

  const subMenuItems = useMemo(
    () =>
      SUB_MENU_ITEMS.filter((item) => applicationConfig[item.id].canRead).map(
        (menuItem) => ({
          ...menuItem,
          handleTabClick: handleHistoryPush,
          label: t(menuItem.label),
        })
      ),
    [applicationConfig, handleHistoryPush, t]
  );

  const menuItems = useMemo(
    () =>
      MENU_ITEMS.filter((item) =>
        item.value === 'icom' ? applicationConfig.transitions.icom : true
      ).map((menuItem) => ({
        ...menuItem,
        label: t(menuItem.label),
      })),
    [applicationConfig.transitions.icom, t]
  );

  const onLogout = () => {
    dsp(logout());
  };

  const exitLabel = t('Выйти');

  return (
    <Header
      changeLanguage={changeLanguage}
      className={css['header-container']}
      exitLabel={exitLabel}
      languages={languages}
      locationPathname={pathname}
      logoSmall={Logo}
      logout={onLogout}
      menuItems={menuItems}
      selectedLanguage={language}
      subMenuItems={subMenuItems}
      userName={userName}
      onChangeSubMenu={handleHistoryPush}
    />
  );
};
