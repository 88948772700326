import { type Types, types } from '../types';
import {
  type ParticipantSource,
  type ParticipantStatus,
  type ParticipantStatusExt,
  type ProductGroupModeration,
  type PackageType,
  type ProductGroupStatusCode,
} from '../../api-generated';

import { type PaginationData, type Name, type PageState } from '.';

type ParticipantData = {
  inn: string;
  name: Name;
  status: ParticipantStatus;
  source: ParticipantSource;
  status_ext: ParticipantStatusExt | null;
  gs1_active: boolean;
};

type UserData = {
  id: number;
  full_name: string;
};

type ProductGroupData = {
  id: number;
  code: string;
  name: Name;
  status: {
    code: ProductGroupStatusCode;
    name: Name;
  };
  moderation: ProductGroupModeration;
  packages: PackageType[];
};

const PAGINATION_DEFAULT: PaginationData = {
  limit: 10,
  offset: 0,
  page: 0,
};

type TaskStatus = 'CREATED' | 'IN_PROGRESS' | 'DONE_ACCEPTED' | 'DONE_REJECTED';

type ProductData = {
  id: string;
  name: Name;
  category_name: Name | null;
  product_group: ProductGroupData;
};

type TaskData = {
  id: number;
  participant: ParticipantData;
  gtin: string;
  status: TaskStatus;
  assigned_user: UserData | null;
  date_create: string;
  date_finish: string | null;
  product: ProductData;
};

type TasksState = PageState<TaskData, TaskData>;

const TABS_ALL = ['MODERATION', 'ALL'] as const;

const initialState: TasksState = {
  list: [],
  item: undefined,
  filter: TABS_ALL.reduce(
    (acc, tab) => ({
      ...acc,
      [tab]: {},
    }),
    {}
  ),
  sorting: TABS_ALL.reduce(
    (acc, tab) => ({
      ...acc,
      [tab]: [],
    }),
    {}
  ),
  isLastPage: false,
  pagination: PAGINATION_DEFAULT,
};

export type TasksActionType = Types[keyof Pick<
  Types,
  | 'SET_TASK_ITEM'
  | 'GET_TASK_ITEM'
  | 'CHANGE_TASK_STATUS'
  | 'ADD_COMMENTS'
  | 'DELETE_COMMENT'
  | 'GET_COMMENTS'
  | 'SET_COMMENTS'
  | 'SET_TASK_STATUS_CHANGED'
  | 'GET_REPORT'
>];

type TasksAction = {
  type: TasksActionType;
  payload: unknown;
};

export const reducer = (
  state: TasksState = initialState,
  action: TasksAction
): TasksState => {
  switch (action.type) {
    case types.SET_TASK_ITEM:
      return { ...state, item: action.payload as TaskData };
    case types.SET_TASK_STATUS_CHANGED: {
      const data = action.payload as TaskData;
      const list = [...state.list].map((item) =>
        item.id === data.id ? { ...item, status: data.status } : item
      );
      return {
        ...state,
        list: [...list],
        item: {
          ...state.item,
          status: data.status,
        } as TaskData,
      };
    }
    default:
      return state;
  }
};
