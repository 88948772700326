import { createAction } from '../../helpers/redux';
import { TasksActionType } from '../reducers/tasks';
import { types } from '../types';

const createTasksAction = createAction<TasksActionType>;

export const getItem = createTasksAction(types.GET_TASK_ITEM);
export const setItem = createTasksAction(types.SET_TASK_ITEM);
export const changeTaskStatus = createTasksAction(types.CHANGE_TASK_STATUS);
export const setTaskStatusChanged = createTasksAction(
  types.SET_TASK_STATUS_CHANGED
);
export const addComments = createTasksAction(types.ADD_COMMENTS);
export const deleteComments = createTasksAction(types.DELETE_COMMENT);
export const getComments = createTasksAction(types.GET_COMMENTS);
export const setComments = createTasksAction(types.SET_COMMENTS);
export const getReport = createTasksAction(types.GET_REPORT);
