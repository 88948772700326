import { useMemo, lazy, ComponentType, ReactElement } from 'react';

import { default as fp } from 'lodash/fp';

import { useAppSelector } from '../store';

import { api, Enum, PackageType } from '.';

type GetEnumQueryResult<T = string> = { data?: Enum<T>; isSuccess: boolean };

type ModuleWithComponent = {
  default: ComponentType<{ children: ReactElement }>;
};

const MODULE_WITH_COMPONENT: ModuleWithComponent = {
  default: ({ children }) => children,
};

const MODULE_LOAD_PROMISE = new Promise<ModuleWithComponent>(
  () => MODULE_WITH_COMPONENT
);

const MODULE_LOAD_PROMISE_RESOLVED = Promise.resolve<ModuleWithComponent>(
  MODULE_WITH_COMPONENT
);

export const useEnums = () => {
  const language = useAppSelector((state) => state.settings.language);

  const { data: packageTypeEnum = [] }: GetEnumQueryResult =
    api.useGetPackageTypeEnumQuery({
      sortLanguage: language,
    });

  const { data: productGroupEnum = [] } = api.useGetProductGroupEnumQuery({
    sortLanguage: language,
  });

  const { data: productStatusEnum = [] }: GetEnumQueryResult =
    api.useGetEnumQuery({
      name: 'product_status',
      sortLanguage: language,
    });

  const { data: unitEnum = [] }: GetEnumQueryResult = api.useGetEnumQuery({
    name: 'units',
    sortLanguage: language,
  });

  const { data: languageEnum = [] }: GetEnumQueryResult = api.useGetEnumQuery({
    name: 'language',
    sortLanguage: language,
  });

  const languages = useMemo(
    () => languageEnum.map(({ code }) => code),
    [languageEnum]
  );

  const { data: photoAngleEnum = [] }: GetEnumQueryResult = api.useGetEnumQuery(
    {
      name: 'photo_angle',
      sortLanguage: language,
    }
  );

  const photoAngles = useMemo(
    () =>
      photoAngleEnum.reduce(
        (acc, { code, translations }) => ({
          ...acc,
          [code]: translations,
        }),
        {} as Record<string, Record<string, string>>
      ),
    [photoAngleEnum]
  );

  const photoAngleOptions = useMemo(
    () =>
      photoAngleEnum.map(({ code, translations }) => ({
        value: code,
        label: translations[language],
      })),
    [language, photoAngleEnum]
  );

  const { data: productAttributeGroupEnum = [] }: GetEnumQueryResult =
    api.useGetEnumQuery({
      name: 'product_attribute_group',
      sortLanguage: language,
    });

  const { data: productAttributeBaseTypeEnum = [] }: GetEnumQueryResult =
    api.useGetEnumQuery({
      name: 'product_attribute_base_type',
      sortLanguage: language,
    });

  const isSuccess = ![
    packageTypeEnum,
    productGroupEnum,
    productStatusEnum,
    unitEnum,
    languageEnum,
    photoAngleEnum,
    productAttributeGroupEnum,
    productAttributeBaseTypeEnum,
  ].some(fp.isEmpty);

  const EnumsLoader = useMemo(
    () =>
      lazy(() =>
        isSuccess ? MODULE_LOAD_PROMISE_RESOLVED : MODULE_LOAD_PROMISE
      ),
    [isSuccess]
  );

  return {
    EnumsLoader,
    language,
    languages,
    languageEnum,
    packageTypeEnum: packageTypeEnum as Enum<PackageType>,
    photoAngles,
    photoAngleOptions,
    photoAngleEnum,
    productAttributeBaseTypeEnum,
    productAttributeGroupEnum,
    productGroupEnum,
    productStatusEnum,
    unitEnum,
  };
};
