import fp from 'lodash/fp';
import { saveAs } from 'file-saver';

export const CONTENT_TYPES = {
  json: 'application/json',
  formdata: 'multipart/form-data',
  form: 'application/x-www-form-urlencoded',
  patch: 'application/json',
  csv: 'text/csv',
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  default: '*/*',
};

const URL_WITHOUT_VERSION = [
  '/oauth',
  '/payments',
  '/mock_recognition',
  '/v3/org/organisation/find?status=REGISTERED',
];

export const saveAsFile = (response, filename: string) => {
  saveAs(response.data, filename.slice(0, -1).slice(1));
  return response;
};

export const checkContentType = fp.curry(
  // eslint-disable-next-line no-bitwise
  (response, contentType: string) =>
    !!~(fp.split('; ', response.headers['content-type'])[0] || '')
      .toLowerCase()
      .indexOf(contentType)
);

export const isHasBody = (method: string) =>
  ['POST', 'PUT', 'PATCH'].includes(method.toUpperCase());

export const getContentDispositionFilename = (response, fallback) =>
  fp.pipe(
    fp.defaultTo(''),
    fp.split(';'),
    fp.map(fp.trim),
    fp.find((v) => /^filename=/i.test(v)),
    fp.split('='),
    fp.last,
    fp.defaultTo(fallback)
  )(response.headers['content-disposition']);

export const isNeedVersionApi = (url: string) =>
  fp.filter(
    // eslint-disable-next-line no-bitwise
    (v) => ~url.indexOf(v)
  )(URL_WITHOUT_VERSION).length > 0;
