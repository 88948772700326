import moment from 'moment';

import { showErrorToast } from '../components/toastWrapper';
import { Translate } from '../components/translate';

const splitKeep = (str, splitter, ahead) => {
  const self = str;
  const result = [];
  if (splitter !== '') {
    // Substitution of matched string
    const getSubst = (value) => {
      const substChar = value[0] === '0' ? '1' : '0';
      let subst = '';
      for (let i = 0; i < value.length; i++) {
        subst += substChar;
      }
      return subst;
    };
    const matches = [];
    // Getting mached value and its index
    const replaceName = splitter instanceof RegExp ? 'replace' : 'replaceAll';

    self[replaceName](splitter, function (m, i) {
      matches.push({
        value: m,
        index: i,
      });
      return getSubst(m);
    });
    // Finds split substrings
    let lastIndex = 0;
    for (let i = 0; i < matches.length; i++) {
      const m = matches[i];
      const nextIndex = ahead ? m.index : m.index + m.value.length;
      if (nextIndex !== lastIndex) {
        const part = self.substring(lastIndex, nextIndex);
        result.push(part);
        lastIndex = nextIndex;
      }
    }
    if (lastIndex < self.length) {
      const part = self.substring(lastIndex, self.length);
      result.push(part);
    }
  } else {
    result.add(self);
  }
  return result;
};
const uiCreateItem = (itmKey, vo) => {
  return {
    id: itmKey,
    name: vo.CN,
    position: vo.T,
    date: `${moment(vo.validFrom).format('DD.MM.YYYY')} - ${moment(
      vo.validTo
    ).format('DD.MM.YYYY')}`,
    source: vo.subjectName,
    issuer: vo.issuerName,
    number: vo.serialNumber,
    ...vo,
  };
};
const getX500Val = (s, f) => {
  const res = splitKeep(s, /,[A-Z]+=/g, true);
  for (let i in res) {
    const n = res[i].search((i > 0 ? ',' : '') + f + '=');
    if (n !== -1) {
      return res[i].slice(n + f.length + 1 + (i > 0 ? 1 : 0));
    }
  }
  return '';
};

const itemGeneration = (o, i) => 'itm-' + o.serialNumber + '-' + i;

const getCertKeys = (list) => {
  const result = [];
  for (let rec in list) {
    const el = list[rec];
    const vo = {
      disk: el.disk,
      path: el.path,
      certName: el.name,
      serialNumber: el.serialNumber,
      subjectName: el.subjectName,
      validFrom: new Date(el.validFrom),
      validTo: new Date(el.validTo),
      issuerName: el.issuerName,
      publicKeyAlgName: el.publicKeyAlgName,
      CN: getX500Val(el.subjectName, 'CN'),
      TIN: getX500Val(el.subjectName, 'INITIALS'),
      O: getX500Val(el.subjectName, 'O'),
      T: getX500Val(el.subjectName, 'T'),
      type: 'certkey',
      businesscategory: getX500Val(el.subjectName, 'businesscategory'),
    };
    if (!vo.TIN) {
      continue;
    }
    const itmkey = itemGeneration(vo, rec);
    const itm = uiCreateItem(itmkey, vo);
    result.push(itm);
  }
  return result;
};

const getPfxs = (list) => {
  const result = [];
  for (let rec in list) {
    const el = list[rec];

    let x500name_ex = el.alias.toUpperCase();
    x500name_ex = x500name_ex.replace('1.2.860.3.16.1.1=', 'INN=');
    x500name_ex = x500name_ex.replace('1.2.860.3.16.1.2=', 'PINFL=');
    const vo = {
      disk: el.disk,
      path: el.path,
      certName: el.name,
      alias: el.alias,
      serialNumber: getX500Val(x500name_ex, 'SERIALNUMBER'),
      validFrom: new Date(
        getX500Val(x500name_ex, 'VALIDFROM')
          .replace(/\./g, '-')
          .replace(' ', 'T')
      ),
      validTo: new Date(
        getX500Val(x500name_ex, 'VALIDTO').replace(/\./g, '-').replace(' ', 'T')
      ),
      CN: getX500Val(x500name_ex, 'CN'),
      TIN: getX500Val(x500name_ex, 'INN')
        ? getX500Val(x500name_ex, 'INN')
        : getX500Val(x500name_ex, 'PINFL'),
      UID: getX500Val(x500name_ex, 'UID'),
      PINFL: getX500Val(x500name_ex, 'PINFL') || null,
      O: getX500Val(x500name_ex, 'O'),
      T: getX500Val(x500name_ex, 'T'),
      type: 'pfx',
      businesscategory: getX500Val(x500name_ex, 'BUSINESSCATEGORY'),
    };
    if (!vo.TIN) {
      continue;
    }
    const itmkey = itemGeneration(vo, rec);
    const itm = uiCreateItem(itmkey, vo);
    result.push(itm);
  }
  return result;
};

const getFTJC = (list) => {
  const result = [];
  for (let rec in list) {
    const el = list[rec];
    let x500name_ex = el.info.toUpperCase();
    x500name_ex = x500name_ex.replace('1.2.860.3.16.1.1=', 'INN=');
    x500name_ex = x500name_ex.replace('1.2.860.3.16.1.2=', 'PINFL=');
    var vo = {
      cardUID: el.cardUID,
      statusInfo: el.statusInfo,
      ownerName: el.ownerName,
      info: el.info,
      serialNumber: getX500Val(x500name_ex, 'SERIALNUMBER'),
      validFrom: new Date(getX500Val(x500name_ex, 'VALIDFROM')),
      validTo: new Date(getX500Val(x500name_ex, 'VALIDTO')),
      CN: getX500Val(x500name_ex, 'CN'),
      TIN: getX500Val(x500name_ex, 'INN')
        ? getX500Val(x500name_ex, 'INN')
        : getX500Val(x500name_ex, 'UID'),
      UID: getX500Val(x500name_ex, 'UID'),
      O: getX500Val(x500name_ex, 'O'),
      T: getX500Val(x500name_ex, 'T'),
      type: 'ftjc',
      businesscategory: getX500Val(x500name_ex, 'businesscategory'),
    };
    if (!vo.TIN) {
      continue;
    }
    const itmkey = itemGeneration(vo, rec);
    const itm = uiCreateItem(itmkey, vo);
    result.push(itm);
  }
  return result;
};

export const prepareAllCertificateList = (certkey, pfx, ftjc) => {
  const certkeyList = getCertKeys(certkey.certificates);
  const pfxList = getPfxs(pfx.certificates);
  const ftjcList = getFTJC(ftjc.tokens);
  return [...certkeyList, ...pfxList, ...ftjcList];
};

export class EimzoService {
  static webSocket;
  static callback;
  static webSocketUrl = 'ws://127.0.0.1:64646/service/cryptapi';
  static EIMZO_MAJOR = 3;
  static EIMZO_MINOR = 37;

  static API_KEYS = [
    'localhost',
    '96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B',
    '127.0.0.1',
    'A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F',
    'null',
    'E0A205EC4E7B78BBB56AFF83A733A1BB9FD39D562E67978CC5E7D73B0951DB1954595A20672A63332535E13CC6EC1E1FC8857BB09E0855D7E76E411B6FA16E9D',
    'dls.yt.uz',
    'EDC1D4AB5B02066FB3FEB9382DE6A7F8CBD095E46474B07568BC44C8DAE27B3893E75B79280EA82A38AD42D10EA0D600E6CE7E89D1629221E4363E2D78650516',
    'asllikbelgisi.uz',
    '087D1F465DFFBFC3C05809F4A31A9AE95144DC1355455A63B8A1FCC9FC517726D91938F1D2C0D5F39643AEDF7762608BBABF481F6FE0C6D8A15C4E7461B72870',
    'goods.asllikbelgisi.uz',
    '4168EB17CB7FA605EC675C958BE89A5631C188CC9DC04FB3883CA9BE9D7F24BD9136FCDA2C7C33BF7B7FAD0219FD6F454357054AFADD8FBD567868C37703A594',
    'aslbelgisi.uz',
    'D619B1B8BEC0AAC90A00AE788703A86334AB2F9E7D7732279A0DDF499440989A233316DC5BB3631A557A4B29153C91D9E78E0BED8A73FE487F16DF8A9E84E7A3',
    'goods.aslbelgisi.uz',
    'C9DC8F6CB11447457535AD1946C3C3237B903575B2625D6054B51B2C69F899336E4DAF1C7F4F8ED092917F9C9CEB8D234A7686BC3A6F1B357B9D48C8ECC2CA2A',
    'goods.stage.aslbelgisi.uz',
    '6B352D724A378EDEFA95F14536D22EF23CDFFC5D5CAAAC316722CD16C16105182A783C46BA4D7A363B39D085D62038935C4A7FE5111CE1FFED13513D1B3028C7',
    'stage.aslbelgisi.uz',
    '253695B706CCF19AC708F124AE1E9DCE58F67511D018F8EDFCC365E71893BC602270736317D5C418BA0B110F6B743819A17ECC8C675F87D85B0621405E504B1A',
    'rmt.aslbelgisi.uz',
    'BC0885691EF6A0F59779B5F262074549273870B5A4370757795025A407890E3D18E7257FDB23B98B8CD45580A4B643FF95478B9AC12D31AB9A4DC1D5CCB47403',
    'rmt.stage.aslbelgisi.uz',
    '972228A88696E7A7DAFDEBD38C528AA3C410545D09F7AF4CD8439C056016EF306AF35D171548FD892D8F62D456E9DC98E12F3A7A47DEE0CB2E47C7B5BA5625D0',
  ];

  static enumerateCertificates(name, plugin, args) {
    return new Promise((resolve) => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = (result) => resolve(result);
        const data = {
          plugin,
          name,
        };
        if (args) {
          data.arguments = args;
        }
        EimzoService.webSocket.send(JSON.stringify(data));
      }
    });
  }

  static getVersion() {
    return new Promise((resolve) => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = (result) => resolve(result);

        EimzoService.webSocket.send(
          JSON.stringify({
            name: 'version',
          })
        );
      }
    });
  }

  static installApiKeys() {
    return new Promise((resolve) => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = (result) => resolve(result);

        EimzoService.webSocket.send(
          JSON.stringify({
            arguments: EimzoService.API_KEYS,
            name: 'apikey',
          })
        );
      }
    });
  }

  static isConnectionAlive() {
    return EimzoService.webSocket?.readyState === 1;
  }

  static isNotAvailable() {
    return (
      EimzoService.webSocket === undefined ||
      EimzoService.webSocket.readyState === 0 ||
      EimzoService.webSocket.readyState === 2 ||
      EimzoService.webSocket.readyState === 3
    );
  }

  static isSystemMessage(message) {
    // eslint-disable-next-line no-prototype-builtins
    return message.result && message.result.hasOwnProperty('version');
  }

  static loadKey(itemObject) {
    const vo = itemObject;
    const args =
      vo.type === 'certkey'
        ? [vo.disk, vo.path, vo.certName, vo.serialNumber]
        : vo.type === 'pfx'
        ? [vo.disk, vo.path, vo.certName, vo.alias]
        : [vo.cardUID];

    return new Promise((resolve) => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = (result) => resolve(result);

        EimzoService.webSocket.send(
          JSON.stringify({
            name: 'load_key',
            plugin: vo.type,
            arguments: args,
          })
        );
      }
    });
  }

  static sign(id, data, isConvertered = false) {
    return new Promise((resolve) => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = (result) => resolve(result);
        const signData = isConvertered ? data : btoa(data);
        const args = [signData, id, 'no'];

        EimzoService.webSocket.send(
          JSON.stringify({
            name: 'create_pkcs7',
            plugin: 'pkcs7',
            arguments: args,
          })
        );
      }
    });
  }

  static init() {
    return new Promise((resolve, reject) => {
      EimzoService.webSocket = new WebSocket(EimzoService.webSocketUrl);

      EimzoService.webSocket.onopen = () =>
        EimzoService.getVersion().then(() =>
          EimzoService.installApiKeys().then(() => resolve())
        );

      EimzoService.webSocket.onerror = () => {
        if (EimzoService.isNotAvailable()) {
          showErrorToast({ content: Translate('E-IMZO не доступен') });
          reject();
        }
      };

      EimzoService.webSocket.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if (response !== null && !EimzoService.isSystemMessage(response)) {
          if (response.minor && response.major) {
            const { minor, major } = response;
            const newVersion =
              EimzoService.EIMZO_MAJOR * 100 + EimzoService.EIMZO_MINOR;
            const installedVersion = parseInt(major) * 100 + parseInt(minor);

            if (installedVersion < newVersion) {
              showErrorToast({
                content: Translate(
                  'ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO.'
                ),
              });
              EimzoService.close();
              reject();
            }
          }
          if (response.success) {
            const result = {
              ...response,
            };
            EimzoService.callback && EimzoService.callback(result);
          } else {
            showErrorToast({ content: 'Не удалось авторизоваться!' });
            reject();
          }
        }
      };
    });
  }

  static close() {
    EimzoService.webSocket.close();
  }
}
