import { type Types, types } from '../types';

type AuthData = {
  access_token?: string;
  isNotAuth?: boolean;
};

type AuthActionType = Types[keyof Pick<
  Types,
  'SET_AUTH_DATA' | 'CLEAR_AUTH_DATA'
>];

type AuthAction = {
  type: AuthActionType;
  payload: AuthData;
};

const initialState = {};

export const reducer = (
  state: AuthData = initialState,
  action: AuthAction
): AuthData => {
  switch (action.type) {
    case types.SET_AUTH_DATA:
      return {
        ...action.payload,
      };
    case types.CLEAR_AUTH_DATA:
      return { isNotAuth: true };
    default:
      return state;
  }
};
