import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { API } from '../../api';
import { setIsLoading } from '../actions/common';
import { setComments, setItem, setTaskStatusChanged } from '../actions/tasks';
import { routes } from '../../routes';
import {
  deleteModerationItemComment,
  getProductSample,
  setModerationItemComment,
} from '../actions/products';
import { prepareComments } from '../../utils/utils';
import { showErrorToast } from '../../components/toastWrapper';

export function* changeTaskStatusSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const { withRedirect, id, onSuccessChangeTaskStatus } = payload;
    const result = yield API.rest.tasks.changeStatus(payload);
    if (withRedirect) {
      const route = payload.route || routes.tasks(id);
      yield put(push(route));
    }
    if (result && !withRedirect) {
      onSuccessChangeTaskStatus && onSuccessChangeTaskStatus(id);
    }
    yield put(setTaskStatusChanged(payload));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* addModerationCommentsSaga({ payload }) {
  try {
    const json = yield API.rest.tasks.addComments(payload);
    yield put(
      setModerationItemComment({
        [json[0].attribute]: {
          reason: {
            [json[0]?.lang]: json[0].typicalComment?.id || 'other',
          },
          customComment: {
            [json[0]?.lang]: json[0].customComment,
          },
          typicalComment: {
            [json[0]?.lang]: json[0]?.typicalComment?.value[json[0]?.lang],
          },
          id: json[0].id,
        },
      })
    );
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}
export function* getModerationCommentsSaga({ payload }) {
  try {
    const json = yield API.rest.tasks.getComments(payload);
    const commentObj = prepareComments(json);
    yield put(setModerationItemComment(commentObj));
    yield put(setComments([...json]));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* loadTaskItemSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.tasks.item(payload);
    yield put(setItem({ ...json }));
    const productId = json?.product.id;
    yield put(getProductSample(productId));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(
      push(routes.serverError('', { code: err?.response?.data?.status || 500 }))
    );
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* deleteCommentSaga({ payload }) {
  try {
    yield API.rest.tasks.deleteComment(payload);
    yield put(deleteModerationItemComment(payload.code));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* getReport({ payload }) {
  try {
    yield API.rest.tasks.getReport(payload);
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}
