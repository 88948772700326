import { createAction } from '../../helpers/redux';
import { types } from '../types';

export const getTemplate = createAction(types.TEMPLATE_GET);
export const setProduct = createAction(types.SET_PRODUCT);
export const updateProductDraft = createAction(types.UPDATE_PRODUCT_DRAFT);
export const createProductDraft = createAction(types.CREATE_PRODUCT_DRAFT);
export const clear = createAction(types.CLEAR_PRODUCT);
export const blockProduct = createAction(types.BLOCK_PRODUCT);

export const getProductSample = createAction(types.GET_PRODUCT_SAMPLE);
export const getProductFirstStepTemplate = createAction(
  types.GET_PRODUCT_FIRST_STEP_TEMPLATE
);
export const setProductFirstStepTemplate = createAction(
  types.SET_PRODUCT_FIRST_STEP_TEMPLATE
);

export const setSignData = createAction(types.SET_SIGN_DATA);
export const toModerateProduct = createAction(types.TO_MODERATE_PRODUCT);
export const getPhoto = createAction(types.GET_PHOTO);
export const setPhoto = createAction(types.SET_PHOTO);
export const setDocs = createAction(types.SET_DOCS);
export const getCopyForEdit = createAction(types.GET_COPY_FOR_EDIT);
export const setCopyForEdit = createAction(types.SET_COPY_FOR_EDIT);
export const getCerts = createAction(types.GET_CERTS);
export const setCerts = createAction(types.SET_CERTS);

export const setModerationItem = createAction(types.SET_MODERATION_ITEM);
export const setModerationBaseInfoItem = createAction(
  types.SET_MODERATION_BASE_INFO_ITEM
);
export const setModerationPhoto = createAction(types.SET_MODERATION_PHOTO);
export const setModerationDoc = createAction(types.SET_MODERATION_DOC);
export const setModerationItemComment = createAction(
  types.SET_MODERATION_ITEM_COMMENT
);
export const deleteModerationItemComment = createAction(
  types.DELETE_MODERATION_ITEM_COMMENT
);
export const toggleGallery = createAction(types.TOGGLE_GALLERY);
export const getCommentsAfterModeration = createAction(
  types.GET_MODERATION_COMMENTS
);
export const loadSignData = createAction(types.LOAD_SIGN_DATA);
