import { all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types } from '../types';

import { downloadFileSaga, uploadFileSaga } from './common';
import {
  loadCategoriesListSaga,
  loadEnumByNameSaga,
  loadNationalClassifierSaga,
  loadTnvedByCategorySaga,
  loadTnvedSaga,
  loadTypicalCommentsSaga,
} from './enums';
import {
  loadTemplateSaga,
  updateProductDraftSaga,
  getProductSampleSaga,
  moderateProductSaga,
  loadGtinListSaga,
  createProductDraftSaga,
  getProductCopyForEditSaga,
  loadCerts,
  uploadFilesSaga,
  removeFilesSaga,
  getCommentsAfterModerationSaga,
  getProductFirstStepTemplateSaga,
  loadSignData,
} from './products';
import { checkAuthSaga, logoutSaga } from './auth';
import {
  addModerationCommentsSaga,
  changeTaskStatusSaga,
  deleteCommentSaga,
  getModerationCommentsSaga,
  loadTaskItemSaga,
  getReport,
} from './tasks';

export function* rootSaga() {
  yield all([
    takeLatest(types.LOGOUT, logoutSaga),
    takeLatest(types.DOWNLOAD_FILE, downloadFileSaga),
    takeLatest(types.UPLOAD_FILE, uploadFileSaga),
    takeLatest(types.TNVED_GET_LIST, loadTnvedSaga),
    takeLatest(types.CATEGORIES_GET_LIST, loadCategoriesListSaga),
    takeLatest(types.TNVED_BY_CATEGORIES_GET_LIST, loadTnvedByCategorySaga),
    takeLatest(types.NATIONAL_GET_LIST, loadNationalClassifierSaga),
    takeLatest(types.TEMPLATE_GET, loadTemplateSaga),
    takeEvery(types.ENUM_GET_LIST, loadEnumByNameSaga),
    takeLatest(types.GET_TYPICAL_COMMENTS, loadTypicalCommentsSaga),
    takeLatest(types.CREATE_PRODUCT_DRAFT, createProductDraftSaga),
    takeLatest(types.UPDATE_PRODUCT_DRAFT, updateProductDraftSaga),
    takeLatest(types.GET_PRODUCT_SAMPLE, getProductSampleSaga),
    takeLatest(
      types.GET_PRODUCT_FIRST_STEP_TEMPLATE,
      getProductFirstStepTemplateSaga
    ),
    takeLatest(types.GET_COPY_FOR_EDIT, getProductCopyForEditSaga),
    takeLatest(types.CHECK_AUTH_DATA, checkAuthSaga),
    takeLatest(types.TO_MODERATE_PRODUCT, moderateProductSaga),
    takeLatest(types.GET_CERTS, loadCerts),
    takeLatest(types.LOAD_SIGN_DATA, loadSignData),
    takeLatest(types.GET_GTINS, loadGtinListSaga),
    takeLatest(types.UPLOAD_FILES, uploadFilesSaga),
    takeLatest(types.REMOVE_FILES, removeFilesSaga),
    takeLatest(types.CHANGE_TASK_STATUS, changeTaskStatusSaga),
    takeLatest(types.ADD_COMMENTS, addModerationCommentsSaga),
    takeLatest(types.GET_COMMENTS, getModerationCommentsSaga),
    takeLatest(types.GET_TASK_ITEM, loadTaskItemSaga),
    takeLatest(types.DELETE_COMMENT, deleteCommentSaga),
    takeLatest(types.GET_MODERATION_COMMENTS, getCommentsAfterModerationSaga),
    takeLatest(types.GET_REPORT, getReport),
  ]);
}
