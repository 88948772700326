// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import { API } from '../../api';
import { showErrorToast } from '../../components/toastWrapper';

export function* downloadFileSaga(action: { payload: any }) {
  try {
    const date = moment().format('YYYYMMDDHHmmss');
    const fileName = `_download_${date}.csv_`;
    yield API.rest.files.download(action.payload, fileName);
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}

export function* uploadFileSaga(action: { payload: any }) {
  try {
    const json = yield API.rest.codes.validate(
      [...action.payload.files],
      '',
      action.payload.query
    );
    action.payload.onFinish(json);
  } catch (err) {
    action.payload.onFinish(false);
    yield showErrorToast({ errors: err });
  }
}
