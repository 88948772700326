export type SidesEnum =
  | 'front_side'
  | 'back_side'
  | 'left_side'
  | 'right_side'
  | 'upper_side'
  | 'down_side'
  | 'other';

export const sides: Record<SidesEnum, string> = {
  front_side: 'Лицевая',
  back_side: 'Обратная',
  left_side: 'Левая',
  right_side: 'Правая',
  upper_side: 'Верхняя',
  down_side: 'Нижняя',
  other: 'Другие',
};

export const photoCodes = [
  'back_side',
  'front_side',
  'down_side',
  'upper_side',
  'right_side',
  'left_side',
  'other',
];

export const MAX_FILE_SIZE_MB = parseInt(window.env.RMT_MAX_FILE_SIZE, 10);
export const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

export const STEPS = [
  {
    label: 'Базовая информация',
    step: 0,
  },
  {
    label: 'Атрибуты товара',
    step: 1,
  },
  {
    label: 'Фото и файлы',
    step: 2,
  },
];
